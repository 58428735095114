import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { KeyClockAuthService } from '../key-clock-auth/key-clock-auth.service';
import { Observable, concatMap, map } from 'rxjs';
import { PlanSelectModel } from 'src/app/model/plan.model';
@Injectable({
  providedIn: 'root'
})
export class WaitingListService {


  constructor(private http: HttpClient, private keyCloakService: KeyClockAuthService) {
  }

  submit(waitingList: { email: string; productType: string; }): Observable<any> {
    return this.keyCloakService.getToken('public-user','public-user' ).pipe(concatMap(token => {
      return this.http.post(`${environment.baseUrl}/api/v2/waiting-lists/custom`, waitingList, {
        headers: {
          Authorization: `Bearer ${token.access_token}`
        }
      });
    }));
  }

  submitWithPlan(plan: PlanSelectModel): Observable<any> {
    return this.keyCloakService.getToken(environment.publicUser, environment.publicUserPassword ).pipe(concatMap(token => {
      return this.http.post(`${environment.baseUrl}/api/v2/waiting-lists/plan`, plan, {
        headers: {
          Authorization: `Bearer ${token.access_token}`
        }
      });
    }));
  }
}
