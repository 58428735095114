export class CookieModel {
  name?: string;
  type?: CookieType;
  duration?: number;
  durationType?: string;
  description?: string;
}

export enum CookieType {
  TECH = 'tech',
  STAT = 'stat',
  MARKETING = 'marketing'
}

export enum CookieKey {
  techCookieConst = 'rk_cookie_tech',
  statCookieConst = 'rk_cookie_stat',
  marketingCookieConst = 'rk_cookie_marketing',
  consentIdConst = 'rk_cookie-consent-id'
}

export interface CookieDTO {
  id: string;
  url: string;
  consentId: string;
  consent: Array<any>;
  bannerType: string;
  country: string;
  policyUpdateVersion: string;
}
