import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppComponent } from './app.component';
import { MatomoModule } from 'ngx-matomo';
import { RouterModule } from '@angular/router';
import { FaqComponent } from './component/faq/faq.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { NgOptimizedImage } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ButtonModule } from 'primeng/button';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CookieComponent } from './component/cookie/cookie.component';
import { BadgeModule } from 'primeng/badge';
import { TableModule } from 'primeng/table';
import { AccordionModule } from 'primeng/accordion';
import { PrivacyPolicyComponent } from './component/privacy-policy/privacy-policy.component';
import { AppRoutingModule } from './app-routing.module';
import { HomeComponent } from './component/home/home.component';
import { TermAndConditionsComponent } from './component/term-and-conditions/term-and-conditions/term-and-conditions.component';
import { SelectPlanComponent } from './component/select-plan/select-plan.component';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    FaqComponent,
    CookieComponent,
    SelectPlanComponent,
    PrivacyPolicyComponent,
    HomeComponent,
    TermAndConditionsComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot([]),
    MatomoModule.forRoot({
      scriptUrl: 'https://analytics.gojodigital.com/matomo.js',
      trackers: [
        {
          trackerUrl: 'https://analytics.gojodigital.com/matomo.php',
          siteId: environment.matomoId
        }
      ],
      routeTracking: {
        enable: true
      }
    }),
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    ToastModule,
    HttpClientModule,
    NgOptimizedImage,
    DialogModule,
    InputSwitchModule,
    ButtonModule,
    ProgressSpinnerModule,
    NgxSpinnerModule.forRoot({ type: 'ball-scale-multiple' }),
    BadgeModule,
    TableModule,
    AccordionModule,
    AppRoutingModule,
    CheckboxModule,
    InputTextModule,
    DropdownModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    { provide: Window, useValue: window },
    MessageService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
