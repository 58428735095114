<section id="faq" class="faq section-bg">
  <div class="container">
    <div class="section-title" data-aos="fade-up">
      <h2>F.A.Q</h2>
      <p>Domande frequenti</p>
    </div>

    <div class="faq-list">
      <ul>
        <li data-aos="fade-up" class="faq-list-container" *ngFor="let faq of faqService.getFaqs()"
          id="{{'faq-list-container-'+faq.id}}">
          <i class="bx bx-help-circle icon-help"></i>
          <a data-bs-toggle="collapse" [attr.aria-expanded]="faq.isOpen" class="collapsed"
            [attr.data-bs-target]="'#faq-list-'+faq.id">
            {{faq.question}}
            <i class="bx bx-chevron-down icon-show"></i>
            <i class="bx bx-chevron-up icon-close"></i></a>
          <div id="{{'faq-list-'+faq.id}}" class="collapse" [ngClass]="{'show': faq.isOpen}" data-bs-parent=".faq-list">
            <p>
              {{faq.answer}}
            </p>
          </div>
        </li>

        <!-- <li data-aos="fade-up" data-aos-delay="100">
          <i class="bx bx-help-circle icon-help"></i>
          <a
            data-bs-toggle="collapse"
            aria-expanded="true"
            data-bs-target="#faq-list-2"
            class="collapsed"
            >Feugiat scelerisque varius morbi enim nunc?
            <i class="bx bx-chevron-down icon-show"></i
            ><i class="bx bx-chevron-up icon-close"></i
          ></a>
          <div id="faq-list-2" class="collapse show" data-bs-parent=".faq-list">
            <p>
              Dolor sit amet consectetur adipiscing elit pellentesque habitant
              morbi. Id interdum velit laoreet id donec ultrices. Fringilla
              phasellus faucibus scelerisque eleifend donec pretium. Est
              pellentesque elit ullamcorper dignissim. Mauris ultrices eros in
              cursus turpis massa tincidunt dui.
            </p>
          </div>
        </li>

        <li data-aos="fade-up" data-aos-delay="200">
          <i class="bx bx-help-circle icon-help"></i>
          <a
            data-bs-toggle="collapse"
            data-bs-target="#faq-list-3"
            class="collapsed"
            >Dolor sit amet consectetur adipiscing elit?
            <i class="bx bx-chevron-down icon-show"></i
            ><i class="bx bx-chevron-up icon-close"></i
          ></a>
          <div id="faq-list-3" class="collapse" data-bs-parent=".faq-list">
            <p>
              Eleifend mi in nulla posuere sollicitudin aliquam ultrices
              sagittis orci. Faucibus pulvinar elementum integer enim. Sem
              nulla pharetra diam sit amet nisl suscipit. Rutrum tellus
              pellentesque eu tincidunt. Lectus urna duis convallis convallis
              tellus. Urna molestie at elementum eu facilisis sed odio morbi
              quis
            </p>
          </div>
        </li>

        <li data-aos="fade-up" data-aos-delay="300">
          <i class="bx bx-help-circle icon-help"></i>
          <a
            data-bs-toggle="collapse"
            data-bs-target="#faq-list-4"
            class="collapsed"
            >Tempus quam pellentesque nec nam aliquam sem et tortor consequat?
            <i class="bx bx-chevron-down icon-show"></i
            ><i class="bx bx-chevron-up icon-close"></i
          ></a>
          <div id="faq-list-4" class="collapse" data-bs-parent=".faq-list">
            <p>
              Molestie a iaculis at erat pellentesque adipiscing commodo.
              Dignissim suspendisse in est ante in. Nunc vel risus commodo
              viverra maecenas accumsan. Sit amet nisl suscipit adipiscing
              bibendum est. Purus gravida quis blandit turpis cursus in.
            </p>
          </div>
        </li>

        <li data-aos="fade-up" data-aos-delay="400">
          <i class="bx bx-help-circle icon-help"></i>
          <a
            data-bs-toggle="collapse"
            data-bs-target="#faq-list-5"
            class="collapsed"
            >Tortor vitae purus faucibus ornare. Varius vel pharetra vel
            turpis nunc eget lorem dolor?
            <i class="bx bx-chevron-down icon-show"></i
            ><i class="bx bx-chevron-up icon-close"></i
          ></a>
          <div id="faq-list-5" class="collapse" data-bs-parent=".faq-list">
            <p>
              Laoreet sit amet cursus sit amet dictum sit amet justo. Mauris
              vitae ultricies leo integer malesuada nunc vel. Tincidunt eget
              nullam non nisi est sit amet. Turpis nunc eget lorem dolor sed.
              Ut venenatis tellus in metus vulputate eu scelerisque.
            </p>
          </div>
        </li> -->
      </ul>
    </div>
  </div>
</section>
