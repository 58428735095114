<section id="hero">
</section>
<!-- End Hero -->

<main id="main-privacy">
  <!-- ======= smartMenu Section ======= -->
  <section id="privacy" class="privacy">
    <div class="container-fluid">
      <div class="row">
        <h2>
          Politica sulla Privacy di www.ristoclick.com
        </h2>

        <p>
          La presente Applicazione raccoglie alcuni Dati Personali dei suoi Utenti.
        </p>
        <p>
          Questa politica sulla privacy può
          essere stampata utilizzando la funzione di stampa disponibile nelle impostazioni di qualsiasi browser.
        </p>
        <p class="subtitle">
          Responsabile del Trattamento dei Dati
        </p>
        <p>
          Dawit Abate Woldeamanuel, Via Attilio Magri 17, MN, 46100
          <br>
          Email del Responsabile: <a href="mailto:info@ristoclick.com">info@ristoclick.com</a>
        </p>
        <p class="subtitle">
          Tipi di Dati raccolti
        </p>
        <p>
          Questa Applicazione raccoglie autonomamente o tramite terze parti i seguenti Dati Personali: nome, cognome,
          email, password, Dati di utilizzo, Strumenti di Tracciamento, indirizzo IP. Informazioni dettagliate su
          ciascun
          tipo di Dati Personali raccolti sono fornite nelle sezioni dedicate di questa politica sulla privacy o
          attraverso specifici testi informativi visualizzati prima della raccolta dei Dati.
        </p>

        <p>
          I Dati Personali possono essere forniti volontariamente dall'Utente o, nel caso di Dati di Utilizzo, raccolti
          automaticamente durante l'uso di questa Applicazione. Se non diversamente specificato, tutti i Dati richiesti
          da
          questa Applicazione sono obbligatori. Se l'Utente rifiuta di fornirli, potrebbe essere impossibile per questa
          Applicazione fornire il Servizio. Nei casi in cui questa Applicazione indichi alcuni Dati come facoltativi,
          gli
          Utenti sono liberi di non comunicare tali Dati, senza che ciò influisca sulla disponibilità del Servizio o
          sulla
          sua operatività. Gli Utenti che abbiano dubbi su quali Dati siano obbligatori sono invitati a contattare il
          Responsabile.
        </p>
        <p>
          L'uso di Cookie - o di altri strumenti di tracciamento - da parte di questa Applicazione o dei titolari dei
          servizi terzi utilizzati da questa Applicazione serve a fornire il Servizio richiesto dall'Utente, oltre alle
          ulteriori finalità descritte in questo documento e nella Cookie Policy.
        <p>
        <p>
          L'Utente si assume la responsabilità dei Dati Personali di terzi ottenuti, pubblicati o condivisi attraverso
          questa Applicazione.
        </p>
        <p class="subtitle">
          Modalità e luogo del trattamento dei Dati raccolti
        </p>
        <p class="paragraph-title">
          Modalità di trattamento</p>
        <p>
          Il Responsabile adotta misure di sicurezza appropriate per prevenire l'accesso, la divulgazione, la modifica o
          la distruzione non autorizzate dei Dati Personali. Il trattamento viene effettuato mediante strumenti
          informatici e/o telematici, con modalità organizzative e logiche strettamente correlate alle finalità
          indicate.
          Oltre al Responsabile, in alcuni casi, potrebbero avere accesso ai Dati altri soggetti coinvolti
          nell'organizzazione di questa Applicazione (personale amministrativo, commerciale, marketing, legali,
          amministratori di sistema) o soggetti esterni (come fornitori di servizi tecnici terzi, corrieri postali,
          hosting provider, società informatiche, agenzie di comunicazione) nominati anche, se necessario, Responsabili
          del Trattamento da parte del Responsabile. L'elenco aggiornato dei Responsabili può essere sempre richiesto al
          Responsabile del Trattamento.
        </p>
        <p class="paragraph-title">
          Luogo
        </p>
        <p>
          I Dati sono trattati presso le sedi operative del Responsabile e in qualsiasi altro luogo in cui le parti
          coinvolte nel trattamento siano localizzate. Per ulteriori informazioni, contatta il Responsabile. I Dati
          Personali dell'Utente potrebbero essere trasferiti in un paese diverso da quello in cui l'Utente si trova. Per
          ottenere ulteriori informazioni sul luogo del trattamento l'Utente può fare riferimento alla sezione relativa
          ai
          dettagli sul trattamento dei Dati Personali.
        </p>
        <p class="paragraph-title">
          Periodo di conservazione
        </p>
        <p>
          Se non diversamente indicato in questo documento, i Dati Personali sono trattati e conservati per il tempo
          necessario per le finalità per le quali sono stati raccolti e potrebbero essere conservati per un periodo più
          lungo a causa di eventuali obbligazioni legali o sulla base del consenso degli Utenti.
        </p>
        <p class="subtitle">
          Finalità del Trattamento dei Dati raccolti
        </p>
        <p>
          I Dati dell'Utente sono raccolti per consentire al Responsabile di fornire il Servizio, adempiere agli
          obblighi
          di legge, rispondere a richieste o azioni esecutive, tutelare i propri diritti ed interessi (o quelli di
          Utenti
          o di terze parti), individuare eventuali attività dolose o fraudolente, nonché per le seguenti finalità:
          Registrazione ed autenticazione fornite direttamente da questa Applicazione, Registrazione ed autenticazione,
          Gestione dei pagamenti, Hosting ed infrastruttura backend e Statistica. Per ottenere informazioni dettagliate
          sulle finalità del trattamento e sui Dati Personali trattati per ciascuna finalità, l'Utente può fare
          riferimento alla sezione “Dettagli sul trattamento dei Dati Personali”.
        </p>
        <p class="subtitle">
          Dettagli sul trattamento dei Dati Personali
        </p>
        <div>
          I Dati Personali sono raccolti per le seguenti finalità ed utilizzando i seguenti servizi:
          <ul>
            <li>
              Registrazione ed autenticazione
              Registrazione diretta (Questa Applicazione)
              L'Utente si registra compilando il modulo di registrazione e fornendo i propri Dati Personali direttamente
              a
              questa Applicazione.
            </li>
            <li>
              Gestione dei pagamenti
              I servizi di gestione dei pagamenti permettono a questa Applicazione di processare pagamenti tramite carta
              di
              credito, bonifico bancario o altri strumenti. I Dati utilizzati per il pagamento sono acquisiti
              direttamente
              dal gestore del servizio di pagamento richiesto senza essere in alcun modo trattati da questa
              Applicazione.
            </li>
            <li>
              Hosting ed infrastruttura backend
              Questo tipo di servizi ha la funzione di ospitare Dati e file che permettono a questa Applicazione di
              funzionare, ne consentono la distribuzione e mettono a disposizione un'infrastruttura pronta all'uso per
              erogare specifiche funzionalità di questa Applicazione. Alcuni di questi servizi funzionano attraverso
              server dislocati geograficamente nell'unione europea.
            </li>
            <li>
              Statistica
              I servizi contenuti nella presente sezione permettono al Titolare del Trattamento di monitorare e
              analizzare
              i dati di traffico e servono a tener traccia del comportamento dell'Utente. Le analisi vengono effettuate
              internamente
              senza l'uso di strumenti di terze parti.
            </li>
          </ul>
        </div>
        <p class="subtitle">
          Cookie Policy
        </p>
        <p>
          Questa Applicazione utilizza Strumenti di Tracciamento. Per saperne di più, gli Utenti possono consultare la
          Cookie Policy.
        </p>
        <p>
          Ulteriori informazioni per gli utenti
          Base giuridica del trattamento
        </p>
        <p>
          Il Responsabile tratta Dati Personali relativi all'Utente se esiste una delle seguenti condizioni:
        </p>
        <div>
          <ul>
            <li>
              l'Utente ha dato il consenso per una o più finalità specifiche.
            </li>
            <li>
              il trattamento è necessario per l'esecuzione di un contratto con l'Utente e/o per l'esecuzione di misure
              precontrattuali;
            </li>
            <li>
              il trattamento è necessario per adempiere ad un obbligo legale al quale è soggetto il Responsabile;
            </li>
            <li>
              il trattamento è necessario per l'esecuzione di un compito di interesse pubblico o per l'esercizio di
              pubblici
              poteri di cui è investito il Responsabile;
            </li>
            <li>
              il trattamento è necessario per il perseguimento del legittimo interesse del Responsabile o di terzi.
              È comunque sempre possibile chiedere al Responsabile di chiarire la base giuridica concreta di ciascun
              trattamento ed in particolare di specificare se il trattamento sia basato sulla legge, previsto da un
              contratto
              o necessario per concludere un contratto.
            </li>
          </ul>
        </div>
        <p>
          Ulteriori informazioni sul tempo di conservazione
          Se non diversamente indicato in questo documento, i Dati Personali sono trattati e conservati per il tempo
          necessario per le finalità per le quali sono stati raccolti e potrebbero essere conservati per un periodo più
          lungo a causa di eventuali obbligazioni legali o sulla base del consenso degli Utenti. Pertanto:
        </p>
        <div>
          <ul>
            <li>
              I Dati Personali raccolti per scopi collegati all'esecuzione di un contratto tra il Responsabile e
              l'Utente
              saranno trattenuti fino a quando sia completata l'esecuzione di tale contratto.
            </li>
            <li>
              I Dati Personali raccolti per finalità riconducibili all'interesse legittimo del Responsabile saranno
              trattenuti fino al soddisfacimento di tale interesse. L'Utente può ottenere ulteriori informazioni in
              merito
              all'interesse legittimo perseguito dal Responsabile nelle relative sezioni di questo documento o
              contattando
              il
              Responsabile.
            </li>
          </ul>
        </div>
        <p>
          Quando il trattamento è basato sul consenso dell'Utente, il Responsabile può conservare i Dati Personali più a
          lungo fino a quando detto consenso non venga revocato. Inoltre, il Responsabile potrebbe essere obbligato a
          conservare i Dati Personali per un periodo più lungo per adempiere ad un obbligo di legge o per ordine di
          un'autorità.
        </p>
        <p>
          Al termine del periodo di conservazione i Dati Personali saranno cancellati. Pertanto, allo scadere di tale
          termine il diritto di accesso, cancellazione, rettificazione ed il diritto alla portabilità dei Dati non
          potranno più essere esercitati.
        </p>
        <p>
          Diritti dell'Utente sulla base del Regolamento Generale sulla Protezione dei Dati (GDPR)
          Gli Utenti possono esercitare determinati diritti con riferimento ai Dati trattati dal Responsabile. In
          particolare, nei limiti previsti dalla legge, l'Utente ha il diritto di:
        </p>
        <div>
          <ul>
            <li>
              revocare il consenso in ogni momento. L'Utente può revocare il consenso al trattamento dei propri Dati
              Personali precedentemente espresso.
            </li>
            <li>
              opporsi al trattamento dei propri Dati. L'Utente può opporsi al trattamento dei propri Dati quando esso
              avviene su una base giuridica diversa dal consenso.
            </li>
            <li>
              accedere ai propri Dati. L'Utente ha diritto ad ottenere informazioni sui Dati trattati dal Responsabile,
              su
              determinati aspetti del trattamento ed a ricevere una copia dei Dati trattati.
            </li>
            <li>
              verificare e chiedere la rettificazione. L'Utente può verificare la correttezza dei propri Dati e
              richiederne
              l'aggiornamento o la correzione.
            </li>
            <li>
              ottenere la limitazione del trattamento. L'Utente può richiedere la limitazione del trattamento dei propri
              Dati. In tal caso il Responsabile non tratterà i Dati per alcun altro scopo se non la loro conservazione.
            </li>
            <li>
              ottenere la cancellazione o rimozione dei propri Dati Personali. L'Utente può richiedere la cancellazione
              dei
              propri Dati da parte del Responsabile.
            </li>
            <li>
              ricevere i propri Dati o farli trasferire ad altro responsabile. L'Utente ha diritto di ricevere i propri
              Dati
              in formato strutturato, di uso comune e leggibile da dispositivo automatico e, ove tecnicamente fattibile,
              di
              ottenerne il trasferimento senza ostacoli ad un altro responsabile.
            </li>
            <li>
              proporre reclamo. L'Utente può proporre un reclamo all'autorità di controllo della protezione dei dati
              personali competente o agire in sede giudiziale.
              Gli Utenti hanno diritto di ottenere informazioni in merito alla base giuridica per il trasferimento di
              Dati
              all'estero incluso verso qualsiasi organizzazione internazionale regolata dal diritto internazionale o
              costituita da due o più paesi, come ad esempio l'ONU, nonché in merito alle misure di sicurezza adottate
              dal
              Responsabile per proteggere i loro Dati.
            </li>
          </ul>
        </div>
        <p class="subtitle">
          Dettagli sul diritto di opposizione
        </p>
        <p>
          Quando i Dati Personali sono trattati nell'interesse pubblico, nell'esercizio di pubblici poteri di cui è
          investito il Responsabile o per perseguire un interesse legittimo del Responsabile, gli Utenti hanno diritto
          ad
          opporsi al trattamento per motivi connessi alla loro situazione particolare. Si fa presente agli Utenti che,
          se
          i loro Dati fossero trattati con finalità di marketing diretto, possono opporsi al trattamento in qualsiasi
          momento, gratuitamente e senza fornire alcuna motivazione. Se gli Utenti si oppongono al trattamento per
          finalità di marketing diretto, i Dati Personali non saranno più oggetto di trattamento per tali finalità. Per
          scoprire se il Responsabile tratti Dati con finalità di marketing diretto gli Utenti possono fare riferimento
          alle rispettive sezioni di questo documento.
        </p>
        <p>
          Come esercitare i diritti
          Per esercitare i propri diritti, gli Utenti possono inviare una richiesta ai contatti del Responsabile
          indicati
          in questo documento.
        </p>
        <p>La richiesta è gratuita e il Responsabile risponderà nel più breve tempo possibile, in ogni
          caso entro un mese, fornendo all'Utente tutte le informazioni previste dalla legge. Eventuali rettifiche,
          cancellazioni o limitazioni del trattamento saranno comunicate dal Responsabile a ciascuno dei destinatari, se
          esistenti, a cui sono stati trasmessi i Dati Personali, salvo che ciò si riveli impossibile o implichi uno
          sforzo sproporzionato.
        </p>
        <p>Il Responsabile comunica all'Utente tali destinatari se egli lo richiede.
        </p>
        <p class="subtitle">
          Ulteriori informazioni sul trattamento
        </p>
        <p class="paragraph-title">
          Difesa in giudizio
        </p>
        <p>
          I Dati Personali dell'Utente possono essere utilizzati da parte del Responsabile in giudizio o nelle fasi
          preparatorie alla sua eventuale instaurazione per la difesa da abusi nell'utilizzo di questa Applicazione o
          dei
          Servizi connessi da parte dell'Utente. L'Utente dichiara di essere consapevole che il Responsabile potrebbe
          essere obbligato a rivelare i Dati per ordine delle autorità pubbliche.
        </p>
        <p class="paragraph-title">
          Informative specifiche
        </p>
        <p>
          Su richiesta dell'Utente, in aggiunta alle informazioni contenute in questa politica sulla privacy, questa
          Applicazione potrebbe fornire all'Utente delle informative aggiuntive e contestuali riguardanti Servizi
          specifici, o la raccolta ed il trattamento di Dati Personali.
        </p>
        <p class="paragraph-title">
          Log di sistema e manutenzione
        </p>
        <p>
          Per necessità legate al funzionamento ed alla manutenzione, questa Applicazione e gli eventuali servizi terzi
          da
          essa utilizzati potrebbero raccogliere log di sistema, ossia file che registrano le interazioni e che possono
          contenere anche Dati Personali, quali l'indirizzo IP Utente.
        </p>
        <p class="paragraph-title">
          Informazioni non contenute in questa policy
        </p>
        <p>
          Ulteriori informazioni in relazione al trattamento dei Dati Personali potranno essere richieste in qualsiasi
          momento al Responsabile del Trattamento utilizzando i contatti forniti.
        </p>
        <p class="paragraph-title">
          Modifiche a questa politica sulla privacy
        </p>
        <p>
          Il Responsabile del Trattamento si riserva il diritto di apportare modifiche alla presente politica sulla
          privacy in qualsiasi momento notificandolo agli Utenti su questa pagina e, se possibile, su questa
          Applicazione
          nonché, se tecnicamente e legalmente fattibile, inviando una notifica agli Utenti attraverso uno dei contatti
          di
          cui è in possesso. Si prega quindi di consultare regolarmente questa pagina, facendo riferimento alla data di
          ultima modifica indicata in fondo.
        </p>
        <p>
          Se le modifiche riguardano trattamenti la cui base giuridica è il consenso, il Responsabile provvederà a
          raccogliere nuovamente il consenso dell'Utente, se necessario.
        </p>
      </div>
      <div>
        <p>
          Data ultima modifica: 01/02/2024
        </p>
      </div>
    </div>
  </section>
</main>
