import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { CookieKey, CookieModel, CookieType } from 'src/app/model/cookie.model';
import { CookieService } from 'src/app/service/cookie/cookie.service';

@Component({
  selector: 'app-cookie',
  templateUrl: './cookie.component.html',
  styleUrls: ['./cookie.component.scss']
})
export class CookieComponent implements OnInit {
  @Input() showCookieModal: boolean = true;
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();

  cookieTitle: string = "Questo sito web utilizza i cookie";
  isCookieDetails: boolean = false;
  cookieFormGroup!: FormGroup;
  cookieList: CookieModel[] = [];
  techCookieList: CookieModel[] = [];
  enableClose: boolean = true;

  constructor(
    private spinner: NgxSpinnerService,
    private cookieService: CookieService) { }

  ngOnInit() {
    this.cookieFormGroup = new FormGroup({
      techCookie: new FormControl({ value: true, disabled: true }),
      statCookie: new FormControl({
        value: this.cookieService.getCookieValue(CookieKey.statCookieConst) === 'true',
        disabled: false
      }),
      marketingCookie: new FormControl({
        value: this.cookieService.getCookieValue(CookieKey.marketingCookieConst) === 'true',
        disabled: false
      })
    });
    this.fillCookieList();
  }
  cookieDetails(enable: boolean) {
    this.isCookieDetails = enable;
    if (enable) {
      this.cookieTitle = "Le policy sui cookie di www.ristoclick.com";
    } else {
      this.cookieTitle = "Questo sito web utilizza i cookie";
    }
  }

  submitCookie() {
    let techCookie: boolean = this.cookieFormGroup.get('techCookie')?.value;
    let statCookie: boolean = this.cookieFormGroup.get('statCookie')?.value;
    let marketingCookie: boolean = this.cookieFormGroup.get('marketingCookie')?.value;
    this.cookieService.setDefaultCookies(techCookie, statCookie, marketingCookie, this.closeModal)

  }

  fillCookieList() {
    this.cookieList = this.cookieService.getFullList();
    this.techCookieList = this.cookieList.filter(c => c.type === CookieType.TECH);
  }
}
