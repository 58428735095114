export enum FeatureEnum {
  MenuFromImage = 0,
  AIDishImage = 1,
  MultiMenu = 2,
  GroupOrder = 3,
  OrderFromTable = 4,
  TableManagement = 5,
  KitchenManagement = 6,
  BasicStats = 7,
  WaiterApp = 8,
  TablePayment = 9,
  CrossSelling = 10,
  CustomFeature = 11
}
