import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KeyClockAuthService } from '../key-clock-auth/key-clock-auth.service';
import { PlanEnum } from 'src/app/model/planEnum';

@Injectable({
  providedIn: 'root'
})
export class PlanService {


  isModalVisibile: boolean = false;
  plan: PlanEnum = PlanEnum.Intermediate;

  constructor(private httpClient: HttpClient,
    private keyCloakService: KeyClockAuthService) {
  }

  showPlanModal(show: boolean) {
    this.isModalVisibile = show;
  }
}
