<!-- ======= Header ======= -->
<router-outlet></router-outlet>

<header id="header" class="fixed-top d-flex align-items-center header-transparent">
  <div class="container d-flex align-items-center justify-content-between">
    <div class="logo">
      <h1>
        <a href="index.html"><span>RistoClick</span></a>
        <a href="index.html"><img src="assets/img/logo.png" alt="" class="img-fluid"></a>
      </h1>
    </div>

    <nav id="navbar" [ngClass]="mobileMenu ? 'navbar-mobile' : 'navbar'">
      <ul>
        <li>
          <a class="nav-link scrollto active" href="#" (click)="closeMobileMenu()">{{ 'NAV.HOME' | translate }}</a>
        </li>
        <li>
          <a class="nav-link scrollto" href="#smartMenu" (click)="closeMobileMenu()">{{ 'NAV.SMART_MENU' | translate }}</a>
        </li>
        <li>
          <a class="nav-link scrollto" href="#features" (click)="closeMobileMenu()">{{ 'NAV.FEATURES' | translate }}</a>
        </li>
        <li>
          <a class="nav-link scrollto" href="#gallery" (click)="closeMobileMenu()">{{ 'NAV.GALLERY' | translate }}</a>
        </li>
        <li *ngIf="team">
          <a class="nav-link scrollto" href="#team" (click)="closeMobileMenu()">{{ 'NAV.TEAM' | translate }}</a>
        </li>
        <li>
          <a class="nav-link scrollto" href="#pricing" (click)="closeMobileMenu()">{{ 'NAV.PRICING' | translate }}</a>
        </li>
        <li class="dropdown" *ngIf="dropDown">
          <a href="#">
            <span>{{ 'NAV.DROP_DOWN' | translate }}</span>
            <i class="bi bi-chevron-down"></i>
          </a>
          <ul>
            <li><a href="#">{{ 'NAV.DROP_DOWN_1' | translate }}</a></li>
            <li class="dropdown">
              <a href="#"><span>{{ 'NAV.DEEP_DROP_DOWN' | translate }}</span> <i class="bi bi-chevron-right"></i></a>
              <ul>
                <li><a href="#">{{ 'NAV.DEEP_DROP_DOWN_1' | translate }}</a></li>
                <li><a href="#">{{ 'NAV.DEEP_DROP_DOWN_2' | translate }}</a></li>
                <li><a href="#">{{ 'NAV.DEEP_DROP_DOWN_3' | translate }}</a></li>
                <li><a href="#">{{ 'NAV.DEEP_DROP_DOWN_4' | translate }}</a></li>
                <li><a href="#">{{ 'NAV.DEEP_DROP_DOWN_5' | translate }}</a></li>
              </ul>
            </li>
            <li><a href="#">{{ 'NAV.DROP_DOWN_2' | translate }}</a></li>
            <li><a href="#">{{ 'NAV.DROP_DOWN_3' | translate }}</a></li>
            <li><a href="#">{{ 'NAV.DROP_DOWN_4' | translate }}</a></li>
          </ul>
        </li>
        <li>
          <a class="nav-link scrollto" href="#contact" (click)="closeMobileMenu()">{{ 'NAV.CONTACT' | translate }}</a>
        </li>
        <li class="dropdown">
          <a href="#">
            <span>{{ 'NAV.LANGUAGE' | translate }}</span>
            <i class="bi bi-chevron-down"></i>
          </a>
          <ul>
            <li><a (click)="selectLanguage('it')">{{ 'NAV.ITALIAN' | translate }}</a></li>
            <li><a (click)="selectLanguage('en')">{{ 'NAV.ENGLISH' | translate }}</a></li>
          </ul>
        </li>
      </ul>
      <i class="bi bi-list mobile-nav-toggle" [ngClass]="mobileMenu ? 'bi-x' : 'bi-list'" (click)="toggleMobileMenu()"></i>
    </nav>
    <!-- .navbar -->
  </div>
</header>
<!-- End Header -->
<a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a>
<div id="preloader" *ngIf="loading"></div>

<!-- ======= Footer ======= -->
<footer id="footer">
  <div class="footer-top">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-6">
          <div class="footer-info">
            <h3>RistoClick</h3>
            <p class="pb-3">
              <em>{{ 'FOOTER.TAGLINE' | translate }}</em>
            </p>
            <p>
              <strong>{{ 'FOOTER.PHONE' | translate }}</strong> +39 351-8222971<br />
              <strong>{{ 'FOOTER.EMAIL' | translate }}</strong> info@ristoclick.com<br />
            </p>
          </div>
        </div>

        <div class="col-lg-2 col-md-6 footer-links">
          <h4>{{ 'FOOTER.USEFUL_LINKS' | translate }}</h4>
          <ul>
            <li><i class="bx bx-chevron-right"></i> <a href="#">{{ 'NAV.HOME' | translate }}</a></li>
            <li><i class="bx bx-chevron-right"></i> <a href="#smartMenu">{{ 'NAV.SMART_MENU' | translate }}</a></li>
            <li><i class="bx bx-chevron-right"></i> <a href="#features">{{ 'NAV.FEATURES' | translate }}</a></li>
            <li><i class="bx bx-chevron-right"></i> <a href="/term-and-conditions">{{ 'FOOTER.TERMS_OF_SERVICE' | translate }}</a></li>
            <li><i class="bx bx-chevron-right"></i> <a href="/privacy">{{ 'FOOTER.PRIVACY_POLICY' | translate }}</a></li>
            <li><i class="bx bx-chevron-right"></i> <a (click)="showCookieModal()" class="cookie-link">{{ 'FOOTER.COOKIE_POLICY' | translate }}</a></li>
          </ul>
        </div>

        <div class="col-lg-2 col-md-6 footer-links" *ngIf="services">
          <h4>{{ 'FOOTER.OUR_SERVICES' | translate }}</h4>
          <ul>
            <li><i class="bx bx-chevron-right"></i> <a href="#">{{ 'FOOTER.WEB_DESIGN' | translate }}</a></li>
            <li><i class="bx bx-chevron-right"></i> <a href="#">{{ 'FOOTER.WEB_DEVELOPMENT' | translate }}</a></li>
            <li><i class="bx bx-chevron-right"></i> <a href="#">{{ 'FOOTER.PRODUCT_MANAGEMENT' | translate }}</a></li>
            <li><i class="bx bx-chevron-right"></i> <a href="#">{{ 'FOOTER.MARKETING' | translate }}</a></li>
            <li><i class="bx bx-chevron-right"></i> <a href="#">{{ 'FOOTER.GRAPHIC_DESIGN' | translate }}</a></li>
          </ul>
        </div>

        <div id="footer-newsletter" class="col-lg-4 col-md-6 footer-newsletter">
          <h4>{{ 'FOOTER.WAITING_LIST' | translate }}</h4>
          <p>{{ 'FOOTER.WAITING_LIST_DESC' | translate }}</p>
          <form class="form" [formGroup]="waitingListFG" (ngSubmit)="waitingList()">
            <input type="email" class="form-control" formControlName="email" name="email" id="email" placeholder="{{ 'FOOTER.YOUR_EMAIL' | translate }}" required />
            <input type="submit" value="{{ 'FOOTER.SUBSCRIBE' | translate }}" />
          </form>
          <div *ngIf="waitingListFG.controls['email']?.status === 'INVALID' && (this.waitingListFG.controls['email']?.dirty || this.waitingListFG.controls['email']?.touched)">
            <div *ngIf="waitingListFG.controls['email'].errors?.['required']">{{ 'FOOTER.EMAIL_REQUIRED' | translate }}</div>
            <div *ngIf="waitingListFG.controls['email'].errors?.['email']">{{ 'FOOTER.EMAIL_INVALID' | translate }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="copyright">
      &copy; Copyright <strong><span>GOJO Digital</span></strong>. {{ 'FOOTER.ALL_RIGHTS_RESERVED' | translate }}
    </div>
  </div>
</footer>
<!-- End Footer -->

<p-toast></p-toast>
<app-cookie [showCookieModal]="cookieService.isModalVisibile" (closeModal)="closeCookieModal()"></app-cookie>
<app-select-plan [showModal]="planService.isModalVisibile" (closeModal)="closePlanModal()"></app-select-plan>
<ngx-spinner type="ball-scale-multiple"></ngx-spinner>
