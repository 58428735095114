import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConsoleService {

  constructor() { }

  welcomeConsoleLog() {
    console.log(`
    ▄▄▄▄ ▄▄▄▄ ▄▄▄      ▄▄▄
    ███▌ ███▄ █▀  ▄▄▄  ▀██▌  ▄▄▄   ▄▄▄▄    ▄▄ ▄▄▄ ▄▄   ▄▄▄▄    ▄███▄▄  ▄▄▄▄
     █████████▀ ▐██▄██▌ ██▌ █▀▀▀ ▄██  ██ ███▀███▀███ ██████    ███   ██ ███
      ███  ███   ██▄▄▄  ██▌ ██▄▄ ▀██▄▄██ ███ ███ ███ ███▄▄▄    ███▄▄ ██▄███
       ▀    ▀      ▀     ▀    ▀▀    ▀     ▀   ▀   ▀    ▀▀        ▀     ▀


    ███▀███   ██         ▄█           ▄██▀███ ███▌ ██▀        ███
    ███▄███   ██▌ █████ ███▀▀ ▄█▀██▄ ███   ▀▀ ▐██▌ ███ ▄████▄ ███ ██▀
    ███ ████  ██▌ ▀███▄ ███  ███ ▐██▌████▄  ▄ ▐██  ███ ██▌ ▀  ██████▄
    ███▀ ▀██▌▀███ ████▀  ███▀ ▀███▀▀  ▀▀███▀▀ ████▐███  ▀███▀ ███ ▀███
    `);
    console.log(`
              ████████████████████████████████████████
              ████████████████████████████████████████
              ██████▀▀▀▀▀▀▀▀▀▀▀██████▀▀▀▀▀▀▀▀▀▀▀██████
              ██████           ██████▌          ██████
              ██████           ██████▌          ██████
              ██████           ██████▌          ██████
              ██████           ██████▌          ██████
              ██████▄▄▄▄▄▄▄▄▄▄▄▀▀▀▀▀▀█▄▄▄▄▄▄▄▄▄▄██████
              █████████████████      █████████████████
              █████████████████      █████████████████
              ██████▀▀▀▀▀▀▀▀▀▀▀▄▄▄▄▄▄▀▀▀▀▀▀▀▀▀▀▀██████
              ██████           ██████▌          ██████
              ██████           ██████▌          ██████
              ██████           ██████▌          ██████
              ██████           ██████▌          ██████
              ██████▄▄▄▄▄▄▄▄▄▄▄███████▄▄▄▄▄▄▄▄▄▄██████
              ████████████████████████████████████████
              ████████████████████████████████████████`);
    console.log('For any information, please contact us at info@ristoclick.com')
  }
}
