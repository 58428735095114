<!-- ======= Hero Section ======= -->
<section id="hero">
  <div class="container">
    <div class="row justify-content-between">
      <div class="col-lg-7 pt-5 pt-lg-0 order-2 order-lg-1 d-flex align-items-center">
        <div data-aos="zoom-out">
          <h1>
            {{ 'HERO.MANAGE_RESTAURANT' | translate }} <br />
            <!-- <span>RistoClick</span> -->
          </h1>
          <h2>
            {{ 'HERO.REVOLUTIONIZE_MENU' | translate }}
          </h2>
          <div class="text-center text-lg-start">
            <a href="#pricing" class="btn-get-started scrollto">{{ 'HERO.WAITING_LIST' | translate }}</a>
          </div>
        </div>
      </div>
      <div class="col-lg-4 order-1 order-lg-2 hero-img" data-aos="zoom-out" data-aos-delay="300">
        <img src="assets/img/hero-img-2.png" class="img-fluid animated" alt="" />
      </div>
    </div>
  </div>

  <svg class="hero-waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 24 150 28 " preserveAspectRatio="none">
    <defs>
      <path id="wave-path" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
    </defs>
    <g class="wave1">
      <use xlink:href="#wave-path" x="50" y="3" fill="rgba(255,255,255, .1)" />
    </g>
    <g class="wave2">
      <use xlink:href="#wave-path" x="50" y="0" fill="rgba(255,255,255, .2)" />
    </g>
    <g class="wave3">
      <use xlink:href="#wave-path" x="50" y="9" fill="#fff" />
    </g>
  </svg>
</section>
<!-- End Hero -->


<main id="main">
  <!-- ======= smartMenu Section ======= -->
  <section id="smartMenu" class="about">
    <div class="container-fluid">
      <div class="row">
        <div class="col-xl-5 col-lg-6 video-box d-flex justify-content-center align-items-stretch" data-aos="fade-right">
          <a href="https://youtu.be/FCfRMt9oIm8" class="glightbox play-btn mb-4"></a>
        </div>

        <div
          class="col-xl-7 col-lg-6 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5"
          data-aos="fade-left">
          <h2>{{ 'SMART_MENU.TITLE' | translate }}</h2>
          <p>
            {{ 'SMART_MENU.DESCRIPTION' | translate }}
          </p>

          <div class="icon-box" data-aos="zoom-in" data-aos-delay="100">
            <div class="icon"><i class="bx bx-camera"></i></div>
            <h4 class="title"><a href="" class="title-text">{{ 'SMART_MENU.UPLOAD_TITLE' | translate }}</a></h4>
            <p class="description">
              {{ 'SMART_MENU.UPLOAD_DESCRIPTION' | translate }}
            </p>
          </div>

          <div class="icon-box" data-aos="zoom-in" data-aos-delay="200">
            <div class="icon"><i class="bx bx-edit-alt"></i></div>
            <h4 class="title"><a href="" class="title-text">{{ 'SMART_MENU.CORRECT_TITLE' | translate }}</a></h4>
            <p class="description">
              {{ 'SMART_MENU.CORRECT_DESCRIPTION' | translate }}
            </p>
          </div>

          <div class="icon-box" data-aos="zoom-in" data-aos-delay="300">
            <div class="icon"><i class="bx bx-food-menu"></i></div>
            <h4 class="title"><a href="" class="title-text">{{ 'SMART_MENU.FINALIZE_TITLE' | translate }}</a></h4>
            <p class="description">
              {{ 'SMART_MENU.FINALIZE_DESCRIPTION' | translate }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- End About Section -->

  <!-- ======= Features Section ======= -->
  <section id="features" class="features">
    <div class="container">
      <div class="section-title" data-aos="fade-up">
        <h2>{{ 'FEATURES.TITLE' | translate }}</h2>
        <p>{{ 'FEATURES.SUBTITLE' | translate }}</p>
      </div>

      <div class="row" data-aos="fade-left">
        <div class="col-lg-3 col-md-4">
          <div class="icon-box" data-aos="zoom-in" data-aos-delay="50">
            <i class="ri-icon ri-camera-line"></i>
            <h3>
              <a href="{{ '#faq-list-container-' + FeatureEnum.MenuFromImage }}"
                (click)="markAsOpened(FeatureEnum.MenuFromImage)">{{ 'FEATURES.MENU_FROM_IMAGE' | translate }}</a>
            </h3>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 mt-4 mt-md-0">
          <div class="icon-box" data-aos="zoom-in" data-aos-delay="100">
            <i class="ri-icon ri-money-euro-box-line"></i>
            <h3>
              <a href="{{ '#faq-list-container-' + FeatureEnum.OrderFromTable }}"
                (click)="markAsOpened(FeatureEnum.OrderFromTable)">{{ 'FEATURES.ORDER_FROM_TABLE' | translate }}</a>
            </h3>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 mt-4 mt-md-0">
          <div class="icon-box" data-aos="zoom-in" data-aos-delay="150">
            <i class="ri-icon ri-group-line"></i>
            <h3>
              <a href="{{ '#faq-list-container-' + FeatureEnum.GroupOrder }}"
                (click)="markAsOpened(FeatureEnum.GroupOrder)">{{ 'FEATURES.GROUP_ORDER' | translate }}</a>
            </h3>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 mt-4 mt-lg-0">
          <div class="icon-box" data-aos="zoom-in" data-aos-delay="200">
            <i class="ri-icon ri-knife-line"></i>
            <h3>
              <a href="{{ '#faq-list-container-' + FeatureEnum.KitchenManagement }}"
                (click)="markAsOpened(FeatureEnum.KitchenManagement)">{{ 'FEATURES.KITCHEN_MANAGEMENT' | translate }}</a>
            </h3>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 mt-4">
          <div class="icon-box" data-aos="zoom-in" data-aos-delay="250">
            <i class="ri-icon ri-image-add-line"></i>
            <h3>
              <a href="{{ '#faq-list-container-' + FeatureEnum.AIDishImage }}"
                (click)="markAsOpened(FeatureEnum.AIDishImage)">{{ 'FEATURES.AI_DISH_IMAGE' | translate }}</a>
            </h3>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 mt-4">
          <div class="icon-box" data-aos="zoom-in" data-aos-delay="300">
            <i class="ri-icon ri-folders-line"></i>
            <h3>
              <a href="{{ '#faq-list-container-' + FeatureEnum.MultiMenu }}"
                (click)="markAsOpened(FeatureEnum.MultiMenu)">{{ 'FEATURES.MULTI_MENU' | translate }}</a>
            </h3>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 mt-4">
          <div class="icon-box" data-aos="zoom-in" data-aos-delay="350">
            <i class="ri-icon ri-bank-card-2-line"></i>
            <h3>
              <a href="{{ '#faq-list-container-' + FeatureEnum.TablePayment }}"
                (click)="markAsOpened(FeatureEnum.TablePayment)">{{ 'FEATURES.TABLE_PAYMENT' | translate }}</a>
            </h3>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 mt-4">
          <div class="icon-box" data-aos="zoom-in" data-aos-delay="400">
            <i class="ri-icon ri-smartphone-line"></i>
            <h3>
              <a href="{{ '#faq-list-container-' + FeatureEnum.WaiterApp }}"
                (click)="markAsOpened(FeatureEnum.WaiterApp)">{{ 'FEATURES.WAITER_APP' | translate }}</a>
            </h3>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 mt-4">
          <div class="icon-box" data-aos="zoom-in" data-aos-delay="450">
            <i class="ri-icon ri-lightbulb-flash-line"></i>
            <h3>
              <a href="{{ '#faq-list-container-' + FeatureEnum.CrossSelling }}"
                (click)="markAsOpened(FeatureEnum.CrossSelling)">{{ 'FEATURES.CROSS_SELLING' | translate }}</a>
            </h3>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 mt-4">
          <div class="icon-box" data-aos="zoom-in" data-aos-delay="500">
            <i class="ri-icon ri-qr-code-line"></i>
            <h3>
              <a href="{{ '#faq-list-container-' + FeatureEnum.TableManagement }}"
                (click)="markAsOpened(FeatureEnum.TableManagement)">{{ 'FEATURES.TABLE_MANAGEMENT' | translate }}</a>
            </h3>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 mt-4">
          <div class="icon-box" data-aos="zoom-in" data-aos-delay="550">
            <i class="ri-icon ri-line-chart-line"></i>
            <h3>
              <a href="{{ '#faq-list-container-' + FeatureEnum.BasicStats }}"
                (click)="markAsOpened(FeatureEnum.BasicStats)">{{ 'FEATURES.BASIC_STATS' | translate }}</a>
            </h3>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 mt-4">
          <div class="icon-box" data-aos="zoom-in" data-aos-delay="600">
            <i class="ri-icon ri-add-box-line"></i>
            <h3>
              <a href="{{ '#faq-list-container-' + FeatureEnum.CustomFeature }}"
                (click)="markAsOpened(FeatureEnum.CustomFeature)">{{ 'FEATURES.CUSTOM_FEATURE' | translate }}</a>
            </h3>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- End Features Section -->

  <!-- ======= Counts Section ======= -->
  <section id="counts" class="counts" *ngIf="counts">
    <div class="container">
      <div class="row" data-aos="fade-up">
        <div class="col-lg-3 col-md-6">
          <div class="count-box">
            <i class="bi bi-emoji-smile"></i>
            <span data-purecounter-start="0" data-purecounter-end="232" data-purecounter-duration="1" class="purecounter"></span>
            <p>{{ 'COUNTS.HAPPY_CLIENTS' | translate }}</p>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 mt-5 mt-md-0">
          <div class="count-box">
            <i class="bi bi-journal-richtext"></i>
            <span data-purecounter-start="0" data-purecounter-end="521" data-purecounter-duration="1" class="purecounter"></span>
            <p>{{ 'COUNTS.PROJECTS' | translate }}</p>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 mt-5 mt-lg-0">
          <div class="count-box">
            <i class="bi bi-headset"></i>
            <span data-purecounter-start="0" data-purecounter-end="1463" data-purecounter-duration="1" class="purecounter"></span>
            <p>{{ 'COUNTS.HOURS_SUPPORT' | translate }}</p>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 mt-5 mt-lg-0">
          <div class="count-box">
            <i class="bi bi-people"></i>
            <span data-purecounter-start="0" data-purecounter-end="15" data-purecounter-duration="1" class="purecounter"></span>
            <p>{{ 'COUNTS.HARD_WORKERS' | translate }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- End Counts Section -->

  <!-- ======= Details Section ======= -->
  <section id="details" class="details">
    <div class="container">
      <div class="row content">
        <div class="col-md-4" data-aos="fade-right">
          <img ngSrc="assets/img/details-1.jpg" width="344" height="400" class="img-fluid" alt="" />
        </div>
        <div class="col-md-8 pt-4" data-aos="fade-up">
          <h2>{{ 'DETAILS.COLLABORATIVE_MENU_TITLE' | translate }}</h2>
          <p class="fst-italic">
            {{ 'DETAILS.COLLABORATIVE_MENU_DESCRIPTION' | translate }}
          </p>
          <ul>
            <li>
              <i class="bi bi-check"></i>
              {{ 'DETAILS.COLLABORATIVE_MENU_FEATURE_1' | translate }}
            </li>
            <li>
              <i class="bi bi-check"></i>
              {{ 'DETAILS.COLLABORATIVE_MENU_FEATURE_2' | translate }}
            </li>
            <li>
              <i class="bi bi-check"></i>
              {{ 'DETAILS.COLLABORATIVE_MENU_FEATURE_3' | translate }}
            </li>
            <li>
              <i class="bi bi-check"></i>
              {{ 'DETAILS.COLLABORATIVE_MENU_FEATURE_4' | translate }}
            </li>
          </ul>
        </div>
      </div>

      <div class="row content">
        <div class="col-md-4 order-1 order-md-2" data-aos="fade-left">
          <img ngSrc="assets/img/waiters.jpg" width="400" height="400" class="img-fluid" alt="" />
        </div>
        <div class="col-md-8 pt-5 order-2 order-md-1" data-aos="fade-up">
          <h2>{{ 'DETAILS.WAITER_APP_TITLE' | translate }}</h2>
          <p class="fst-italic">
            {{ 'DETAILS.WAITER_APP_DESCRIPTION' | translate }}
          </p>
          <p>
            {{ 'DETAILS.WAITER_APP_FEATURES' | translate }}
          </p>
        </div>
      </div>

      <div class="row content">
        <div class="col-md-4" data-aos="fade-right">
          <img ngSrc="assets/img/details-3.jpg" width="600" height="600" class="img-fluid" alt="" />
        </div>
        <div class="col-md-8 pt-5" data-aos="fade-up">
          <h2>{{ 'DETAILS.KITCHEN_MANAGEMENT_TITLE' | translate }}</h2>
          <p>
            {{ 'DETAILS.KITCHEN_MANAGEMENT_DESCRIPTION' | translate }}
          </p>
          <ul>
            <li>
              <i class="bi bi-check"></i>
              {{ 'DETAILS.KITCHEN_MANAGEMENT_FEATURE_1' | translate }}
            </li>
            <li>
              <i class="bi bi-check"></i>
              {{ 'DETAILS.KITCHEN_MANAGEMENT_FEATURE_2' | translate }}
            </li>
            <li>
              <i class="bi bi-check"></i>
              {{ 'DETAILS.KITCHEN_MANAGEMENT_FEATURE_3' | translate }}
            </li>
            <li>
              <i class="bi bi-check"></i>
              {{ 'DETAILS.KITCHEN_MANAGEMENT_FEATURE_4' | translate }}
            </li>
          </ul>
        </div>
      </div>

      <div class="row content">
        <div class="col-md-4 order-1 order-md-2" data-aos="fade-left">
          <img ngSrc="assets/img/details-4.jpg" width="344" height="400" class="img-fluid" alt="" />
        </div>
        <div class="col-md-8 pt-5 order-2 order-md-1" data-aos="fade-up">
          <h2>{{ 'DETAILS.MANAGE_RESTAURANT_TITLE' | translate }}</h2>
          <p class="fst-italic">
            {{ 'DETAILS.MANAGE_RESTAURANT_DESCRIPTION' | translate }}
          </p>
          <ul>
            <li>
              <i class="bi bi-check"></i>
              {{ 'DETAILS.MANAGE_RESTAURANT_FEATURE_1' | translate }}
            </li>
            <li>
              <i class="bi bi-check"></i>
              {{ 'DETAILS.MANAGE_RESTAURANT_FEATURE_2' | translate }}
            </li>
            <li>
              <i class="bi bi-check"></i>
              {{ 'DETAILS.MANAGE_RESTAURANT_FEATURE_3' | translate }}
            </li>
          </ul>
          <p>
            {{ 'DETAILS.MANAGE_RESTAURANT_EXTRA' | translate }}
          </p>
        </div>
      </div>
    </div>
  </section>

  <!-- End Details Section -->

  <!-- ======= Gallery Section ======= -->
  <section id="gallery" class="gallery">
    <div class="container">
      <div class="section-title" data-aos="fade-up">
        <h2>{{ 'GALLERY.TITLE' | translate }}</h2>
        <p>{{ 'GALLERY.SUBTITLE' | translate }}</p>
        <p class="subtitle">{{ 'GALLERY.SUBTITLE_AI' | translate }}</p>
      </div>

      <div class="row g-0" data-aos="fade-left">
        <div class="col-lg-3 col-md-4">
          <div class="gallery-item" data-aos="zoom-in" data-aos-delay="100">
            <a href="assets/img/gallery/gallery-1.jpg" class="gallery-lightbox">
              <img ngSrc="assets/img/gallery/gallery-1.jpg" width="400" height="400" alt="{{ 'GALLERY.ALT_1' | translate }}" class="img-fluid" />
            </a>
          </div>
        </div>

        <div class="col-lg-3 col-md-4">
          <div class="gallery-item" data-aos="zoom-in" data-aos-delay="150">
            <a href="assets/img/gallery/gallery-2.jpg" class="gallery-lightbox">
              <img ngSrc="assets/img/gallery/gallery-2.jpg" width="400" height="400" alt="{{ 'GALLERY.ALT_2' | translate }}" class="img-fluid" />
            </a>
          </div>
        </div>

        <div class="col-lg-3 col-md-4">
          <div class="gallery-item" data-aos="zoom-in" data-aos-delay="200">
            <a href="assets/img/gallery/gallery-3.jpg" class="gallery-lightbox">
              <img ngSrc="assets/img/gallery/gallery-3.jpg" width="400" height="400" alt="{{ 'GALLERY.ALT_3' | translate }}" class="img-fluid" />
            </a>
          </div>
        </div>

        <div class="col-lg-3 col-md-4">
          <div class="gallery-item" data-aos="zoom-in" data-aos-delay="250">
            <a href="assets/img/gallery/gallery-4.jpg" class="gallery-lightbox">
              <img ngSrc="assets/img/gallery/gallery-4.jpg" width="400" height="400" alt="{{ 'GALLERY.ALT_4' | translate }}" class="img-fluid" />
            </a>
          </div>
        </div>

        <div class="col-lg-3 col-md-4">
          <div class="gallery-item" data-aos="zoom-in" data-aos-delay="300">
            <a href="assets/img/gallery/gallery-5.jpg" class="gallery-lightbox">
              <img ngSrc="assets/img/gallery/gallery-5.jpg" width="400" height="400" alt="{{ 'GALLERY.ALT_5' | translate }}" class="img-fluid" />
            </a>
          </div>
        </div>

        <div class="col-lg-3 col-md-4">
          <div class="gallery-item" data-aos="zoom-in" data-aos-delay="350">
            <a href="assets/img/gallery/gallery-6.jpg" class="gallery-lightbox">
              <img ngSrc="assets/img/gallery/gallery-6.jpg" width="400" height="400" alt="{{ 'GALLERY.ALT_6' | translate }}" class="img-fluid" />
            </a>
          </div>
        </div>

        <div class="col-lg-3 col-md-4">
          <div class="gallery-item" data-aos="zoom-in" data-aos-delay="400">
            <a href="assets/img/gallery/gallery-7.jpg" class="gallery-lightbox">
              <img ngSrc="assets/img/gallery/gallery-7.jpg" width="400" height="400" alt="{{ 'GALLERY.ALT_7' | translate }}" class="img-fluid" />
            </a>
          </div>
        </div>

        <div class="col-lg-3 col-md-4">
          <div class="gallery-item" data-aos="zoom-in" data-aos-delay="450">
            <a href="assets/img/gallery/gallery-8.jpg" class="gallery-lightbox">
              <img ngSrc="assets/img/gallery/gallery-8.jpg" width="400" height="400" alt="{{ 'GALLERY.ALT_8' | translate }}" class="img-fluid" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- End Gallery Section -->

  <!-- ======= Testimonials Section ======= -->
  <section id="testimonials" class="testimonials" *ngIf="testimonials">
    <div class="container">
      <div class="testimonials-slider swiper" data-aos="fade-up" data-aos-delay="100">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <div class="testimonial-item">
              <img src="assets/img/testimonials/testimonials-1.jpg" class="testimonial-img" alt="" />
              <h3>Saul Goodman</h3>
              <h4>Ceo &amp; Founder</h4>
              <p>
                <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                Proin iaculis purus consequat sem cure digni ssim donec
                porttitora entum suscipit rhoncus. Accusantium quam, ultricies
                eget id, aliquam eget nibh et. Maecen aliquam, risus at semper.
                <i class="bx bxs-quote-alt-right quote-icon-right"></i>
              </p>
            </div>
          </div>
          <!-- End testimonial item -->

          <div class="swiper-slide">
            <div class="testimonial-item">
              <img src="assets/img/testimonials/testimonials-2.jpg" class="testimonial-img" alt="" />
              <h3>Sara Wilsson</h3>
              <h4>Designer</h4>
              <p>
                <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                Export tempor illum tamen malis malis eram quae irure esse
                labore quem cillum quid cillum eram malis quorum velit fore eram
                velit sunt aliqua noster fugiat irure amet legam anim culpa.
                <i class="bx bxs-quote-alt-right quote-icon-right"></i>
              </p>
            </div>
          </div>
          <!-- End testimonial item -->

          <div class="swiper-slide">
            <div class="testimonial-item">
              <h3>Jena Karlis</h3>
              <h4>Store Owner</h4>
              <p>
                <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                Enim nisi quem export duis labore cillum quae magna enim sint
                quorum nulla quem veniam duis minim tempor labore quem eram duis
                noster aute amet eram fore quis sint minim.
                <i class="bx bxs-quote-alt-right quote-icon-right"></i>
              </p>
            </div>
          </div>
          <!-- End testimonial item -->

          <div class="swiper-slide">
            <div class="testimonial-item">
              <img src="assets/img/testimonials/testimonials-4.jpg" class="testimonial-img" alt="" />
              <h3>Matt Brandon</h3>
              <h4>Freelancer</h4>
              <p>
                <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                Fugiat enim eram quae cillum dolore dolor amet nulla culpa
                multos export minim fugiat minim velit minim dolor enim duis
                veniam ipsum anim magna sunt elit fore quem dolore labore illum
                veniam.
                <i class="bx bxs-quote-alt-right quote-icon-right"></i>
              </p>
            </div>
          </div>
          <!-- End testimonial item -->

          <div class="swiper-slide">
            <div class="testimonial-item">
              <img src="assets/img/testimonials/testimonials-5.jpg" class="testimonial-img" alt="" />
              <h3>John Larson</h3>
              <h4>Entrepreneur</h4>
              <p>
                <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                Quis quorum aliqua sint quem legam fore sunt eram irure aliqua
                veniam tempor noster veniam enim culpa labore duis sunt culpa
                nulla illum cillum fugiat legam esse veniam culpa fore nisi
                cillum quid.
                <i class="bx bxs-quote-alt-right quote-icon-right"></i>
              </p>
            </div>
          </div>
          <!-- End testimonial item -->
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </div>
  </section>
  <!-- End Testimonials Section -->

  <!-- ======= Team Section ======= -->
  <section id="team" class="team" *ngIf="team">
    <div class="container">
      <div class="section-title" data-aos="fade-up">
        <h2>Team</h2>
        <p>Our Great Team</p>
      </div>

      <div class="row" data-aos="fade-left">
        <div class="col-lg-3 col-md-6">
          <div class="member" data-aos="zoom-in" data-aos-delay="100">
            <div class="pic">
              <img src="assets/img/team/team-1.jpg" class="img-fluid" alt="" />
            </div>
            <div class="member-info">
              <h4>Walter White</h4>
              <span>Chief Executive Officer</span>
              <div class="social">
                <a href=""><i class="bi bi-twitter"></i></a>
                <a href=""><i class="bi bi-facebook"></i></a>
                <a href=""><i class="bi bi-instagram"></i></a>
                <a href=""><i class="bi bi-linkedin"></i></a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 mt-5 mt-md-0">
          <div class="member" data-aos="zoom-in" data-aos-delay="200">
            <div class="pic">
              <img src="assets/img/team/team-2.jpg" class="img-fluid" alt="" />
            </div>
            <div class="member-info">
              <h4>Sarah Jhonson</h4>
              <span>Product Manager</span>
              <div class="social">
                <a href=""><i class="bi bi-twitter"></i></a>
                <a href=""><i class="bi bi-facebook"></i></a>
                <a href=""><i class="bi bi-instagram"></i></a>
                <a href=""><i class="bi bi-linkedin"></i></a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 mt-5 mt-lg-0">
          <div class="member" data-aos="zoom-in" data-aos-delay="300">
            <div class="pic">
              <img src="assets/img/team/team-3.jpg" class="img-fluid" alt="" />
            </div>
            <div class="member-info">
              <h4>William Anderson</h4>
              <span>CTO</span>
              <div class="social">
                <a href=""><i class="bi bi-twitter"></i></a>
                <a href=""><i class="bi bi-facebook"></i></a>
                <a href=""><i class="bi bi-instagram"></i></a>
                <a href=""><i class="bi bi-linkedin"></i></a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 mt-5 mt-lg-0">
          <div class="member" data-aos="zoom-in" data-aos-delay="400">
            <div class="pic">
              <img src="assets/img/team/team-4.jpg" class="img-fluid" alt="" />
            </div>
            <div class="member-info">
              <h4>Amanda Jepson</h4>
              <span>Accountant</span>
              <div class="social">
                <a href=""><i class="bi bi-twitter"></i></a>
                <a href=""><i class="bi bi-facebook"></i></a>
                <a href=""><i class="bi bi-instagram"></i></a>
                <a href=""><i class="bi bi-linkedin"></i></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Team Section -->

  <!-- ======= Pricing Section ======= -->
  <section id="pricing" class="pricing">
    <div class="container">
      <div class="section-title" data-aos="fade-up">
        <h2>{{ 'PRICING.TITLE' | translate }}</h2>
        <p>{{ 'PRICING.SUBTITLE' | translate }}</p>
      </div>

      <div class="row" data-aos="fade-left">
        <div class="col-lg-3 col-md-6">
          <div class="box" data-aos="zoom-in" data-aos-delay="100">
            <h3>{{ 'PRICING.BASIC_PLAN.TITLE' | translate }}</h3>
            <h4><sup>€</sup>0<span> / {{ 'PRICING.PER_MONTH' | translate }}</span></h4>
            <ul>
              <li>{{ 'PRICING.BASIC_PLAN.FEATURE_1' | translate }}</li>
              <li>{{ 'PRICING.BASIC_PLAN.FEATURE_2' | translate }}</li>
              <li>{{ 'PRICING.BASIC_PLAN.FEATURE_3' | translate }}</li>
              <li>{{ 'PRICING.BASIC_PLAN.FEATURE_4' | translate }}</li>
              <li>{{ 'PRICING.BASIC_PLAN.FEATURE_5' | translate }}</li>
              <li>{{ 'PRICING.BASIC_PLAN.FEATURE_6' | translate }}</li>
              <li>{{ 'PRICING.BASIC_PLAN.FEATURE_7' | translate }}</li>
              <li class="na">{{ 'PRICING.BASIC_PLAN.FEATURE_8' | translate }}</li>
              <li>
                <span class="na">{{ 'PRICING.BASIC_PLAN.FEATURE_9' | translate }}</span>
                <span class="ai">IA</span>
              </li>
              <li>
                <span class="na">{{ 'PRICING.BASIC_PLAN.FEATURE_10' | translate }}</span>
                <span class="ai">IA</span>
              </li>
              <li class="na">{{ 'PRICING.BASIC_PLAN.FEATURE_11' | translate }}
                <span class="self-payment">🔥</span>
              </li>
              <li class="na">{{ 'PRICING.BASIC_PLAN.FEATURE_12' | translate }}</li>
              <li class="na">{{ 'PRICING.BASIC_PLAN.FEATURE_13' | translate }}</li>
            </ul>
            <div class="btn-wrap">
              <a class="btn-buy" (click)="activatePlan(PlanEnum.Base)">{{ 'PRICING.BUTTON_WAITING_LIST' | translate }}</a>
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 mt-4 mt-md-0">
          <div class="box featured" data-aos="zoom-in" data-aos-delay="200">
            <h3>{{ 'PRICING.INTERMEDIATE_PLAN.TITLE' | translate }}</h3>
            <h4 class="margin-0"><sup>€</sup><del>10</del><span> / {{ 'PRICING.PER_MONTH' | translate }}</span></h4>
            <h4 class="margin-0"><sup>€</sup>0<span class="for-waiting-list">{{ 'PRICING.FREE_2024' | translate }}</span></h4>
            <ul>
              <li>{{ 'PRICING.INTERMEDIATE_PLAN.FEATURE_1' | translate }}</li>
              <li>{{ 'PRICING.INTERMEDIATE_PLAN.FEATURE_2' | translate }}</li>
              <li>{{ 'PRICING.INTERMEDIATE_PLAN.FEATURE_3' | translate }}</li>
              <li>{{ 'PRICING.INTERMEDIATE_PLAN.FEATURE_4' | translate }}</li>
              <li>{{ 'PRICING.INTERMEDIATE_PLAN.FEATURE_5' | translate }}</li>
              <li>{{ 'PRICING.INTERMEDIATE_PLAN.FEATURE_6' | translate }}</li>
              <li>{{ 'PRICING.INTERMEDIATE_PLAN.FEATURE_7' | translate }}</li>
              <li>{{ 'PRICING.INTERMEDIATE_PLAN.FEATURE_8' | translate }}</li>
              <li>
                <span>{{ 'PRICING.INTERMEDIATE_PLAN.FEATURE_9' | translate }}</span>
                <span class="ai">IA</span>
              </li>
              <li>
                <span>{{ 'PRICING.INTERMEDIATE_PLAN.FEATURE_10' | translate }}</span>
                <span class="ai">IA</span>
              </li>
              <li class="na">{{ 'PRICING.INTERMEDIATE_PLAN.FEATURE_11' | translate }}
                <span class="self-payment">🔥</span>
              </li>
              <li class="na">{{ 'PRICING.INTERMEDIATE_PLAN.FEATURE_12' | translate }}</li>
              <li class="na">{{ 'PRICING.INTERMEDIATE_PLAN.FEATURE_13' | translate }}</li>
            </ul>
            <div class="btn-wrap">
              <a (click)="activatePlan(PlanEnum.Intermediate)" class="btn-buy">{{ 'PRICING.BUTTON_WAITING_LIST' | translate }}</a>
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 mt-4 mt-lg-0">
          <div class="box" data-aos="zoom-in" data-aos-delay="300">
            <span class="advanced">{{ 'PRICING.RECOMMENDED' | translate }}</span>
            <h3>{{ 'PRICING.ADVANCED_PLAN.TITLE' | translate }}</h3>
            <h4><sup>€</sup>15<span> / {{ 'PRICING.PER_MONTH' | translate }}</span></h4>
            <ul>
              <li>{{ 'PRICING.ADVANCED_PLAN.FEATURE_1' | translate }}</li>
              <li>{{ 'PRICING.ADVANCED_PLAN.FEATURE_2' | translate }}</li>
              <li>{{ 'PRICING.ADVANCED_PLAN.FEATURE_3' | translate }}</li>
              <li>{{ 'PRICING.ADVANCED_PLAN.FEATURE_4' | translate }}</li>
              <li>{{ 'PRICING.ADVANCED_PLAN.FEATURE_5' | translate }}</li>
              <li>{{ 'PRICING.ADVANCED_PLAN.FEATURE_6' | translate }}</li>
              <li>{{ 'PRICING.ADVANCED_PLAN.FEATURE_7' | translate }}</li>
              <li>{{ 'PRICING.ADVANCED_PLAN.FEATURE_8' | translate }}</li>
              <li>
                <span>{{ 'PRICING.ADVANCED_PLAN.FEATURE_9' | translate }}</span>
                <span class="ai">IA</span>
              </li>
              <li>
                <span>{{ 'PRICING.ADVANCED_PLAN.FEATURE_10' | translate }}</span>
                <span class="ai">IA</span>
              </li>
              <li>{{ 'PRICING.ADVANCED_PLAN.FEATURE_11' | translate }}
                <span class="self-payment">🔥</span>
              </li>
              <li>{{ 'PRICING.ADVANCED_PLAN.FEATURE_12' | translate }}</li>
              <li class="na">{{ 'PRICING.ADVANCED_PLAN.FEATURE_13' | translate }}</li>
            </ul>
            <div class="btn-wrap">
              <a (click)="activatePlan(PlanEnum.Advanced)" class="btn-buy">{{ 'PRICING.BUTTON_WAITING_LIST' | translate }}</a>
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 mt-4 mt-lg-0">
          <div class="box" data-aos="zoom-in" data-aos-delay="400">
            <h3>{{ 'PRICING.ENTERPRISE_PLAN.TITLE' | translate }}</h3>
            <h4><sup>€</sup>-<span> / {{ 'PRICING.PER_MONTH' | translate }}</span></h4>
            <ul>
              <li>{{ 'PRICING.ENTERPRISE_PLAN.FEATURE_1' | translate }}</li>
              <li>{{ 'PRICING.ENTERPRISE_PLAN.FEATURE_2' | translate }}</li>
              <li>{{ 'PRICING.ENTERPRISE_PLAN.FEATURE_3' | translate }}</li>
              <li>{{ 'PRICING.ENTERPRISE_PLAN.FEATURE_4' | translate }}</li>
              <li>{{ 'PRICING.ENTERPRISE_PLAN.FEATURE_5' | translate }}</li>
              <li>{{ 'PRICING.ENTERPRISE_PLAN.FEATURE_6' | translate }}</li>
              <li>{{ 'PRICING.ENTERPRISE_PLAN.FEATURE_7' | translate }}</li>
              <li>{{ 'PRICING.ENTERPRISE_PLAN.FEATURE_8' | translate }}</li>
              <li>
                <span>{{ 'PRICING.ENTERPRISE_PLAN.FEATURE_9' | translate }}</span>
                <span class="ai">IA</span>
              </li>
              <li>
                <span>{{ 'PRICING.ENTERPRISE_PLAN.FEATURE_10' | translate }}</span>
                <span class="ai">IA</span>
              </li>
              <li>{{ 'PRICING.ENTERPRISE_PLAN.FEATURE_11' | translate }}
                <span class="self-payment">🔥</span>
              </li>
              <li>{{ 'PRICING.ENTERPRISE_PLAN.FEATURE_12' | translate }}</li>
              <li>{{ 'PRICING.ENTERPRISE_PLAN.FEATURE_13' | translate }}</li>
            </ul>
            <div class="btn-wrap">
              <a (click)="activatePlan(PlanEnum.Enterprise)" class="btn-buy">{{ 'PRICING.BUTTON_CONTACT_US' | translate }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- End Pricing Section -->

  <!-- ======= F.A.Q Section ======= -->
  <app-faq *ngIf="faq"></app-faq>
  <!-- End F.A.Q Section -->

  <!-- ======= Contact Section ======= -->
  <section id="contact" class="contact">
    <div class="container">
      <div class="section-title" data-aos="fade-up">
        <h2>{{ 'CONTACT.TITLE' | translate }}</h2>
        <p>{{ 'CONTACT.SUBTITLE' | translate }}</p>
      </div>

      <div class="row">
        <div class="col-lg-4 col-md-4" data-aos="fade-right" data-aos-delay="100">
          <div class="info">
            <div class="email">
              <i class="bi bi-envelope"></i>
              <h4>{{ 'CONTACT.EMAIL_TITLE' | translate }}</h4>
              <p>{{ 'CONTACT.EMAIL' | translate }}</p>
            </div>
            <div class="phone">
              <i class="bi bi-phone"></i>
              <h4>{{ 'CONTACT.PHONE_TITLE' | translate }}</h4>
              <p>{{ 'CONTACT.PHONE' | translate }}</p>
            </div>
          </div>
        </div>

        <div class="col-lg-8 col-md-4" data-aos="fade-left" data-aos-delay="200">
          <form action="forms/contact.php" method="post" role="form" class="php-email-form" *ngIf="contactForm">
            <div class="row">
              <div class="col-md-6 form-group">
                <input type="text" name="name" class="form-control" id="name" placeholder="{{ 'CONTACT.YOUR_NAME' | translate }}" required />
              </div>
              <div class="col-md-6 form-group mt-3 mt-md-0">
                <input type="email" class="form-control" name="email" id="email" placeholder="{{ 'CONTACT.YOUR_EMAIL' | translate }}" required />
              </div>
            </div>
            <div class="form-group mt-3">
              <input type="text" class="form-control" name="subject" id="subject" placeholder="{{ 'CONTACT.SUBJECT' | translate }}" required />
            </div>
            <div class="form-group mt-3">
              <textarea class="form-control" name="message" rows="5" placeholder="{{ 'CONTACT.MESSAGE' | translate }}" required></textarea>
            </div>
            <div class="my-3">
              <div class="loading">{{ 'CONTACT.LOADING' | translate }}</div>
              <div class="error-message"></div>
              <div class="sent-message">
                {{ 'CONTACT.SENT_MESSAGE' | translate }}
              </div>
            </div>
          </form>
          <div class="text-center php-email-form" (click)="chatWithUs()">
            <button type="submit" class="submit-button">{{ 'CONTACT.CHAT_WITH_US' | translate }}</button>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Contact Section -->
</main>
<!-- End #main -->
