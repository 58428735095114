import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FaqModel } from 'src/app/model/faq.model';
import { FeatureEnum } from 'src/app/model/featureEnum';

@Injectable({
  providedIn: 'root'
})
export class FaqService {

  constructor(private translate: TranslateService) {


  }

  getFaqs(): FaqModel[] {
    //get current language
    const currentLang = this.translate.currentLang;
    if (currentLang === 'it') {
      return this.faqsIT;
    } else {
      return this.faqsEN;
    }

  }

  private faqsIT: FaqModel[] = [
    {
      question: 'Cosa è la funzionalità Genera menu da foto?',
      answer: 'La funzionalità Genera menu da foto è una funzionalità che permette di creare il tuo menu digitale a partire dalla foto del tuo menu cartaceo. Puoi scattare una foto al tuo menu cartaceo e la nostra intelligenza artificiale creerà il tuo menu digitale. Una volta elaborato il menù, potrai confermare oppure modificare i prodotti e le categorie. Questa funzionalità è molto utile per i ristoranti che vogliono digitalizzare il proprio menu in modo rapido e semplice.',
      isOpen: false,
      id: FeatureEnum.MenuFromImage
    },
    {
      question: 'Cosa è la funzionalità Genera immagini dei piatti?',
      answer: 'La funzionalità Genera immagini dei piatti è una funzionalità che permette di creare le immagini dei tuoi piatti a partire dalla descrizione del piatto. Puoi scrivere la descrizione del piatto e la nostra intelligenza artificiale creerà l\'immagine del piatto. Sebbene il nostro consiglio sia quello di utilizzare immagini reali dei tuoi piatti, questa funzionalità è molto utile per i ristoranti che non hanno immagini dei loro piatti oppure che vogliono creare delle immagini accativanti.',
      isOpen: false,
      id: FeatureEnum.AIDishImage
    },
    {
      question: 'Cosa è la funzionalità Multi menu?',
      answer: 'La funzionalità Multi menu è una funzionalità che permette di creare più menu per il tuo sito ristorante. Ad esempio puoi creare un menu  principale, un menu per le festività ed un menu per le offerte speciali. Puoi attivare e disattivare i menu in qualsiasi momento.',
      isOpen: false,
      id: FeatureEnum.MultiMenu
    },
    {
      question: 'Cosa è la funzionalità Menu collaborativo?',
      answer: 'La funzionalità Menu collaborativo è una funzionalità che permette di ordinare in modo condiviso ai membri di un tavolo. Quando un utente aggiunge o rimuove un prodotto dal carello, tutti gli utenti del tavolo ricevono una notifica. Inoltre, quando un utente del tavolo conferma l\'ordine, tutti gli utenti del tavolo ricevono una notifica. Questa è una funzionalità consente di ordinare in gruppo in modo divertente e coinvolgente.',
      isOpen: false,
      id: FeatureEnum.GroupOrder
    },
    {
      question: 'Cosa è la funzionalità Ordini dal tavolo?',
      answer: 'La funzionalità Ordini dal tavolo è una funzionalità che permette ai tuoi clienti di ordinare direttamente dal tavolo del tuo ristorante. I clienti possono scansionare un QR code e vedere il menu del tuo ristorante sul loro smartphone. Non è richiesto l\'installazione di un\'app. Riceverai un avviso sulle sezioni dedicate della piattaforma. Potrai preparare l\'ordine e portarlo al tavolo del cliente.',
      isOpen: false,
      id: FeatureEnum.OrderFromTable
    },
    {
      question: 'Cosa è la funzionalità Gestione tavoli?',
      answer: 'La funzionalità Gestione tavoli è una funzionalità che permette di gestire i tavoli del tuo ristorante. Puoi creare, modificare e cancellare tavoli. In questa sezione, puoi creare e stampare i codici QR per i tavoli. Puoi anche vedere lo stato dei tavoli e vedere quali tavoli sono occupati e quali sono liberi. Inoltre hai la possibilità di consultare lo storico degli ordini di ogni tavolo.',
      isOpen: false,
      id: FeatureEnum.TableManagement
    },
    {
      question: 'Cosa è la funzionalità Gestione cucina?',
      answer: 'La funzionalità Gestione cucina è una funzionalità che permette di ricevere gli ordini direttamente in cucina. Quando un cliente ordina dal tavolo oppure quando un cameriere inserisce un ordine, ricevi in questa sezione una notifica. Puoi vedere tutti gli ordini in attesa e preparare gli ordini. Quando un ordine è pronto, puoi segnalarlo come pronto e il cameriere riceverà una notifica.',
      isOpen: false,
      id: FeatureEnum.KitchenManagement
    },
    {
      question: 'Cosa è la funzionalità Statistiche basilari?',
      answer: 'La funzionalità Statistiche basilari è una funzionalità che ti permette di vedere lo storico degli oridini, lo stato dei tavoli del ristorante, l\'incasso giornaliero il numero di utenti giornalieri ed altre statistiche basilari. ',
      isOpen: false,
      id: FeatureEnum.BasicStats
    },
    {
      question: 'Cosa è l\' App per camerieri?',
      answer: 'Con funzionalità App per camerieri, avrai a disposizione un app dedicata per i tuoi camerieri. I camerieri potranno vedere lo stato dei tavoli, prendere gli ordini, inviare gli ordini in cucina oppure supportare i clienti che ordinano autonomamente. Questa funzionalità è pensata per i clienti meno digitali che preferiscono ordinare con un cameriere.',
      isOpen: false,
      id: FeatureEnum.WaiterApp
    },
    {
      question: 'Cosa è la funzionalità Pagamento dal tavolo?',
      answer: 'La funzionalità Pagamento dal tavolo è una funzionalità che permette ai tuoi clienti di pagare direttamente dal tavolo del tuo ristorante. I clienti una volta che hanno finito di mangiare, possono pagare direttamente dal tavolo. Senza dover aspettare il conto oppure fare la fila alla cassa.',
      isOpen: false,
      id: FeatureEnum.TablePayment
    },
    {
      question: 'Cosa è la funzionalità Cross selling?',
      answer: 'La funzionalità Cross selling è una funzionalità che permette di suggerire prodotti correlati a quelli che il cliente sta visualizzando. Inoltre sulla base degli ordini precedenti e preferenze del cliente, grazie alla nostra intelligenza artificiale, suggeriamo prodotti che potrebbero interessare al cliente. Questa funzionalità permette al ristorante di aumentare il valore medio dell\'ordine e allo stesso tempo di offire prelibatezza di interesse ai clienti. Questa funzionalità è in fase di sviluppo e sarà disponibile a breve.',
      isOpen: false,
      id: FeatureEnum.CrossSelling
    },
    {
      question: 'Cosa solo le Funzionalità personalizzate?',
      answer: 'Per Funzionalità personalizzate si intendono tutte quelle funzionalità che non sono presenti nella piattaforma e che vengono sviluppate su richiesta del cliente. Ad esempio, se hai bisogno di una funzionalità specifica per il tuo ristorante, puoi contattarci e valuteremo insieme la fattibilità e il costo di sviluppo.',
      isOpen: false,
      id: FeatureEnum.CustomFeature
    },

  ];

  private faqsEN: FaqModel[] = [
    {
      question: 'What is the "Generate menu from photo" feature?',
      answer: 'The "Generate menu from photo" feature allows you to create your digital menu from a photo of your paper menu. You can take a photo of your paper menu and our artificial intelligence will create your digital menu. Once the menu is processed, you can confirm or modify the products and categories. This feature is very useful for restaurants that want to digitize their menu quickly and easily.',
      isOpen: false,
      id: FeatureEnum.MenuFromImage
    },
    {
      question: 'What is the "Generate dish images" feature?',
      answer: 'The "Generate dish images" feature allows you to create images of your dishes from the dish description. You can write the description of the dish and our artificial intelligence will create the image of the dish. Although we recommend using real images of your dishes, this feature is very useful for restaurants that do not have images of their dishes or want to create attractive images.',
      isOpen: false,
      id: FeatureEnum.AIDishImage
    },
    {
      question: 'What is the "Multi menu" feature?',
      answer: 'The "Multi menu" feature allows you to create multiple menus for your restaurant website. For example, you can create a main menu, a holiday menu, and a special offers menu. You can enable and disable the menus at any time.',
      isOpen: false,
      id: FeatureEnum.MultiMenu
    },
    {
      question: 'What is the "Collaborative menu" feature?',
      answer: 'The "Collaborative menu" feature allows shared ordering among members of a table. When a user adds or removes a product from the cart, all table users receive a notification. Additionally, when a table user confirms the order, all table users receive a notification. This feature provides a fun and engaging group ordering experience.',
      isOpen: false,
      id: FeatureEnum.GroupOrder
    },
    {
      question: 'What is the "Order from table" feature?',
      answer: 'The "Order from table" feature allows your customers to order directly from their table in your restaurant. Customers can scan a QR code and view your restaurant\'s menu on their smartphone. No app installation is required. You will receive a notification on the dedicated sections of the platform. You can then prepare the order and deliver it to the customer\'s table.',
      isOpen: false,
      id: FeatureEnum.OrderFromTable
    },
    {
      question: 'What is the "Table management" feature?',
      answer: 'The "Table management" feature allows you to manage the tables in your restaurant. You can create, edit, and delete tables. In this section, you can create and print QR codes for the tables. You can also see the status of the tables and see which tables are occupied and which are free. Additionally, you have the ability to consult the order history of each table.',
      isOpen: false,
      id: FeatureEnum.TableManagement
    },
    {
      question: 'What is the "Kitchen management" feature?',
      answer: 'The "Kitchen management" feature allows you to receive orders directly in the kitchen. When a customer orders from the table or when a waiter enters an order, you receive a notification in this section. You can see all pending orders and prepare the orders. When an order is ready, you can mark it as ready and the waiter will receive a notification.',
      isOpen: false,
      id: FeatureEnum.KitchenManagement
    },
    {
      question: 'What is the "Basic statistics" feature?',
      answer: 'The "Basic statistics" feature allows you to see the order history, the status of the restaurant tables, the daily revenue, the number of daily users, and other basic statistics.',
      isOpen: false,
      id: FeatureEnum.BasicStats
    },
    {
      question: 'What is the "Waiter app" feature?',
      answer: 'With the "Waiter app" feature, you will have a dedicated app for your waiters. Waiters can see the status of the tables, take orders, send orders to the kitchen, or assist customers who order independently. This feature is designed for less tech-savvy customers who prefer to order with a waiter.',
      isOpen: false,
      id: FeatureEnum.WaiterApp
    },
    {
      question: 'What is the "Table payment" feature?',
      answer: 'The "Table payment" feature allows your customers to pay directly from their table in your restaurant. Once they have finished eating, customers can pay directly from the table without waiting for the bill or queuing at the cash register.',
      isOpen: false,
      id: FeatureEnum.TablePayment
    },
    {
      question: 'What is the "Cross selling" feature?',
      answer: 'The "Cross selling" feature allows you to suggest related products to the ones the customer is viewing. Additionally, based on previous orders and customer preferences, our artificial intelligence suggests products that might interest the customer. This feature allows the restaurant to increase the average order value while offering appealing items to customers. This feature is in development and will be available soon.',
      isOpen: false,
      id: FeatureEnum.CrossSelling
    },
    {
      question: 'What are "Custom features"?',
      answer: 'Custom features refer to all those features that are not present in the platform and are developed at the customer\'s request. For example, if you need a specific feature for your restaurant, you can contact us and we will evaluate the feasibility and development cost together.',
      isOpen: false,
      id: FeatureEnum.CustomFeature
    }
  ];

}
