import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { FeatureEnum } from 'src/app/model/featureEnum';
import { PlanEnum } from 'src/app/model/planEnum';
import { ConsoleService } from 'src/app/service/console/console.service';
import { CookieService } from 'src/app/service/cookie/cookie.service';
import { FaqService } from 'src/app/service/faq/faq.service';
import { PlanService } from 'src/app/service/plan-service/plan.service';
import { WaitingListService } from 'src/app/service/waiting-list/waiting-list.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {

  PlanEnum = PlanEnum;
  testimonials = false;
  team = false;
  dropDown = false;
  counts = false;
  faq = true;
  contactForm = false;
  services = false;
  mobileMenu = false;
  faCamera = faCamera;
  waitingListEmail = '';
  FeatureEnum = FeatureEnum;
  waitingListFG: FormGroup;
  checked = false;

  constructor(private faqService: FaqService,
    private fb: FormBuilder,
    private messageService: MessageService,
    private waitingListService: WaitingListService,
    private spinner: NgxSpinnerService,
    private cookieService: CookieService,
    private planService: PlanService) {

    this.waitingListFG = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      productType: ['', []]
    });

  }


  chatWithUs() {
    (window as any).$chatwoot.toggle('open');
  }

  toggleMobileMenu() {
    this.mobileMenu = !this.mobileMenu;
  }

  closeMobileMenu() {
    this.mobileMenu = false;
  }

  markAsOpened(id: number) {
    this.faqService.getFaqs().map((faq) => {
      if (faq.id === id) {
        faq.isOpen = !faq.isOpen;
      } else {
        faq.isOpen = false;
      }
      return faq;
    });
  }



  selectWaitingList(productType: string) {
    this.waitingListFG.controls['productType'].setValue(productType);
  }

  getCookie(cookie: string) {
    return document.cookie.split(';').some((item) => item.trim().startsWith(`${cookie}=`));
  }

  showCookieModal() {
    return this.cookieService.showCookieModal(true);
  }

  activatePlan(plan: PlanEnum) {
    this.planService.isModalVisibile = true;
    this.planService.plan = plan;
    console.log(this.planService.plan);
    console.log(plan);
  }
}
