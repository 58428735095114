import { Component, OnInit } from '@angular/core';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { FaqService } from './service/faq/faq.service';
import { FeatureEnum } from './model/featureEnum';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { WaitingListService } from './service/waiting-list/waiting-list.service';
import { ConsoleService } from './service/console/console.service';
import { CookieService } from './service/cookie/cookie.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { PlanService } from './service/plan-service/plan.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  loading = true;
  mobileMenu = false;
  team = false;
  dropDown = false;
  contactForm = false;
  services = false;
  waitingListFG: FormGroup;

  constructor(
    private consoleService: ConsoleService,
    public cookieService: CookieService,
    private fb: FormBuilder,
    private messageService: MessageService,
    private waitingListService: WaitingListService,
    private spinner: NgxSpinnerService,
    public planService: PlanService,
    private translate: TranslateService,
  ) {
    this.consoleService.welcomeConsoleLog();
    if(environment.showCookieModal){
      this.cookieService.showCookieModal(!this.cookieService.areCookiesPresent());
    }
    setTimeout(() => {
      (window as any).initTheme();
      this.loading = false;
    }, 1000);
    this.waitingListFG = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      productType: ['', []]
    });
    //rileva la lingua del browser
    const browserLang = translate.getBrowserLang();
    translate.setDefaultLang(browserLang != null ? browserLang : 'en');

    console.log(translate.getLangs());
    this.translate.use(browserLang != null ? browserLang : 'en');

  }

  switchLanguage(language: string) {
    this.translate.use(language);
  }

  closeCookieModal() {
    this.cookieService.showCookieModal(false);
  }

  closePlanModal() {
    this.planService.showPlanModal(false);
  }

  toggleMobileMenu() {
    this.mobileMenu = !this.mobileMenu;
  }

  closeMobileMenu() {
    this.mobileMenu = false;
  }

  selectLanguage(lang: string){
    this.mobileMenu = false;
    //set current language to lang
    this.switchLanguage(lang);

  }


  showCookieModal() {
    return this.cookieService.showCookieModal(true);
  }

  waitingList() {
    if (this.waitingListFG.valid) {
      this.spinner.show();

      this.waitingListService
        .submit({ email: this.waitingListFG.value.email, productType: this.waitingListFG.value.productType })
        .subscribe((response) => {
          this.spinner.hide();
          this.messageService.add({ severity: 'success', summary: 'waiting list', detail: 'You have been successfully registered on the waiting list!' });
          this.waitingListFG.reset();
        }, (error) => {
          console.error(error);
          this.spinner.hide();
          this.messageService.add({ severity: 'error', summary: 'waiting list', detail: 'Error registering on the waiting list!' });
        });
    } else {
      if (this.waitingListFG.controls['email'].errors?.['required']) {
      }
      if (this.waitingListFG.controls['email'].errors?.['email']) {
      }
    }
  }
}
