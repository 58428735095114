import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { CookieDTO, CookieKey, CookieModel, CookieType } from 'src/app/model/cookie.model';
import { environment } from 'src/environments/environment';
import { v4 as uuid } from 'uuid';
import { KeyClockAuthService } from '../key-clock-auth/key-clock-auth.service';
import { concatMap, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CookieService {

  isModalVisibile: boolean = false;

  constructor(private httpClient: HttpClient,
    private keyCloakService: KeyClockAuthService) {
  }

  setDefaultCookies(techCookie: boolean, statCookie: boolean, marketingCookie: boolean, closeModal: EventEmitter<void>) {
    let today = new Date();
    let expires = new Date();
    const consentId = uuid();
    expires.setFullYear(today.getFullYear() + 10);

    document.cookie = `${CookieKey.techCookieConst}=${techCookie}; expires=${expires}; path=/`;
    document.cookie = `${CookieKey.statCookieConst}=${statCookie}; expires=${expires}; path=/`;
    document.cookie = `${CookieKey.marketingCookieConst}=${marketingCookie}; expires=${expires}; path=/`;
    document.cookie = `${CookieKey.consentIdConst}=${consentId}; expires=${expires}; path=/`;
    closeModal.emit();
    this.saveCookieConsent();
  }

  saveCookieConsent() {
    let consent: CookieDTO = {
      id: '',
      url: window.location.href,
      consentId: this.getCookieValue(CookieKey.consentIdConst),
      consent: [
        {
          type: CookieType.TECH,
          value: this.getCookieValue(CookieKey.techCookieConst) === 'true'
        },
        {
          type: CookieType.STAT,
          value: this.getCookieValue(CookieKey.statCookieConst) === 'true'
        },
        {
          type: CookieType.MARKETING,
          value: this.getCookieValue(CookieKey.marketingCookieConst) === 'true'
        }
      ],
      bannerType: 'BANNER',
      country: 'IT',
      policyUpdateVersion: '1.0'
    }
    this.keyCloakService.getToken(environment.publicUser, environment.publicUserPassword)
      .pipe(concatMap(token => this.httpClient.post(`${environment.baseUrl}/api/v2/policy/consent`, consent,
        {
          headers: {
            Authorization: `Bearer ${token.access_token}`
          }
        }))
      ).subscribe(res => {
        localStorage.getItem('retryCookie') && localStorage.removeItem('retryCookie');
      }, err => {
        localStorage.setItem('retryCookie', "true");
      });
  }
  areCookiesPresent(): boolean {
    return this.isCookiePresent(CookieKey.techCookieConst) &&
      this.isCookiePresent(CookieKey.statCookieConst) &&
      this.isCookiePresent(CookieKey.marketingCookieConst) &&
      this.isCookiePresent(CookieKey.consentIdConst);
  }

  isCookiePresent(techCookieConst: CookieKey): boolean {
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    return ca.some(c => c.trim().startsWith(techCookieConst + "="));
  }

  getCookieValue(cookieName: string): string {
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    let cookie = ca.find(c => c.trim().startsWith(cookieName + "="));
    if (cookie) {
      return cookie.split('=')[1];
    }
    return '';
  }

  showCookieModal(value: boolean) {
    this.isModalVisibile = value;
    if(!this.isModalVisibile && localStorage.getItem('retryCookie')){
      this.saveCookieConsent();
    }
  }

  showPlanModal(value: boolean) {
    this.isModalVisibile = value;
    if(!this.isModalVisibile && localStorage.getItem('retryCookie')){
      this.saveCookieConsent();
    }
  }

  getFullList(): CookieModel[] {
    return [
      {
        name: "_pk_id",
        description: "Utilizzato per memorizzare anonimamente alcune informazioni sull'utente, come l'identificatore univoco del visitatore.",
        duration: 13,
        durationType: "mesi",
        type: CookieType.TECH
      },
      {
        name: "_pk_ses",
        description: "Utilizzato per memorizzare anonimamente alcune informazioni di breve durata sulla visita",
        duration: 30,
        durationType: "minuti",
        type: CookieType.TECH
      },
      {
        name: "_chatwoot_session",
        description: "Utilizzato per memorizzare informazione su eventuali chat di supporto",
        duration: -1,
        durationType: "Sessione",
        type: CookieType.TECH
      },
      {
        name: "cw_conversation",
        description: "Utilizzato per memorizzare lo storico delle chat",
        duration: 12,
        durationType: "mesi",
        type: CookieType.TECH
      },
      {
        name: "cw_user_",
        description: "Utilizzato per memorizzare informazioni sui contatti in chat",
        duration: 12,
        durationType: "mesi",
        type: CookieType.TECH
      },
      {
        name: "rk_cookie_tech",
        description: "Utilizzato per memorizzare la scelta dell'utente sui cookie",
        duration: 10,
        durationType: "anni",
        type: CookieType.TECH
      },

      {
        name: "rk_cookie_stat",
        description: "Utilizzato per memorizzare la scelta dell'utente sui cookie",
        duration: 10,
        durationType: "anni",
        type: CookieType.TECH
      },
      {
        name: "rk_cookie_marketing",
        description: "Utilizzato per memorizzare la scelta dell'utente sui cookie",
        duration: 10,
        durationType: "anni",
        type: CookieType.TECH
      },
      {
        name: "keycloak_",
        description: "Utilizzato per memorizzare informazioni di autenticazione",
        duration: 1,
        durationType: "giorno",
        type: CookieType.TECH
      },
      {
        name: "keycloak_",
        description: "Utilizzato per memorizzare informazioni di autenticazione",
        duration: 1,
        durationType: "giorno",
        type: CookieType.TECH
      },
      {
        name: "rk_cookie-consent-id",
        description: "Utilizzato per memorizzare la scelta dell'utente sui cookie",
        duration: 10,
        durationType: "anni",
        type: CookieType.TECH
      },
    ]
  }
}
