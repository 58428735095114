import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { AuthModel } from 'src/app/model/auth.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class KeyClockAuthService {

  constructor(private httpClient: HttpClient) { }


  getToken(user: string, password: string): Observable<AuthModel> {
    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };
    let body = new URLSearchParams();
    body.set('username', user);
    body.set('password', password);
    body.set('client_id', 'risto-click-public');
    body.set('grant_type', 'password');
    return this.httpClient.post<AuthModel>(`${environment.tokenUrl}/`, body.toString(), options);
  }
}
