import { Component, Input } from '@angular/core';
import { FaqModel } from 'src/app/model/faq.model';
import { FaqService } from 'src/app/service/faq/faq.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent {

  constructor(public faqService: FaqService) { }
}
