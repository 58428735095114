<p-dialog class="cookie-dialog hide-cookie-dialog" [header]="title" [(visible)]="showModal"
  styleClass="cookie-dialog-style">
  <div>
    <i class="ri-icon ri-close-circle-line close-icon" (click)="close()"></i>
  </div>
  <div>
    <form [formGroup]="formGroup">
      <div class="">
        <div class="row">
          <!-- Add 2 empty col -->
          <div class="col-md-5 offset-md-1">
            <div class="input-group">
              <label for="name">{{ 'FORM.NAME' | translate }}</label>
              <input pInputText id="name" class="input" aria-describedby="name-help" formControlName="name" />
              <small class="name-help">
              </small>
            </div>
          </div>
          <div class="col-md-5">
            <div class="input-group">
              <label for="surname">{{ 'FORM.SURNAME' | translate }}</label>
              <input pInputText id="surname" class="input" aria-describedby="surname-help" formControlName="surname" />
              <small class="surname-help">
              </small>
            </div>
          </div>
          <div class="col-md-5 offset-md-1">
            <div class="input-group">
              <label for="email">{{ 'FORM.EMAIL' | translate }}</label>
              <input pInputText id="email" class="input" aria-describedby="email-help" formControlName="email" />
              <small class="email-help">
              </small>
            </div>
          </div>
          <div class="col-md-5">
            <div class="input-group">
              <label for="phone">{{ 'FORM.PHONE' | translate }}</label>
              <input pInputText id="phone" class="input" aria-describedby="phone-help" formControlName="phone" />
              <small class="phone-help">
              </small>
            </div>
          </div>
          <div class="col-md-5 offset-md-1">
            <div class="input-group">
              <label for="plan">{{ 'FORM.PLAN' | translate }}</label>
              <input pInputText id="plan" class="input" aria-describedby="plan-help" formControlName="plan" />
              <small class="plan-help">
              </small>
            </div>
          </div>
          <div class="col-md-10 offset-md-1">
            <div class="input-group">
              <label for="notes">{{ 'FORM.NOTES' | translate }}</label>
              <textarea rows="2" cols="30" class="input" pInputTextareal id="notes" formControlName="notes"></textarea>
              <small class="notes-help">
              </small>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="offset-md-1 col-md-11 col-12">
            <p>
              <p-checkbox formControlName="privacy" class="input" [binary]="true" inputId="binary" />
              {{ 'FORM.PRIVACY' | translate }}
              <a href="/privacy" target="_blank">{{ 'FORM.PRIVACY_LINK' | translate }}</a>.
            </p>
          </div>
        </div>
      </div>
      <div class="row cookie-submit-container">
        <div class="col-12"><p-button class="cookie-submit" label="{{ 'FORM.SUBMIT' | translate }}" (click)="submit()"></p-button>
        </div>
      </div>
    </form>
  </div>
</p-dialog>
