import { Component } from '@angular/core';

@Component({
  selector: 'app-term-and-conditions',
  templateUrl: './term-and-conditions.component.html',
  styleUrls: ['./term-and-conditions.component.scss']
})
export class TermAndConditionsComponent {

}
