<section id="hero">
</section>


<main id="main-term-and-conditions">
  <!-- ======= smartMenu Section ======= -->
  <section id="term-and-conditions" class="term-and-conditions">
    <div class="container-fluid">
      <div class="row">
        <h2 class="subtitle">Condizioni Generali di www.ristoclick.com</h2>
        <p class="paragraph-title">Questi termini regolano:</p>
        <ul>
          <li>L'uso di questa Applicazione</li>
          <li>Qualsiasi altro contratto o relazione legale con il Proprietario in modo vincolante.</li>
        </ul>
        <p>Le espressioni con la lettera iniziale maiuscola sono definite nella sezione pertinente di questo documento.
          Si prega
          l'Utente di leggere attentamente questo documento.</p>
        <p>Questa Applicazione è un servizio di: Dawit Abate Woldeamanuel, Via Attilio Magri 17, MN, 46100</p>
        <p>Indirizzo email del Proprietario: info@ristoclick.com</p>

        <h2 class="subtitle">Informazioni su questa Applicazione</h2>
        <p>RistoClick è una piattaforma digitale che offre ai ristoranti uno strumento per la
          creazione
          di un menù digitale, un sistema per la gestione degli ordini e dei pagamenti direttamente dal tavolo, nonché
          per la
          gestione degli ordini a domicilio e delle relative consegne.</p>
        <p>"Questa Applicazione" si riferisce a:</p>
        <ul>
          <li>Questo sito, inclusi i relativi sottodomini e qualsiasi altro sito attraverso il quale il Proprietario
            offre il
            Servizio;</li>
          <li>Applicazioni per dispositivi mobili, tablet o simili;</li>
          <li>Le application program interfaces (API);</li>
          <li>Il Servizio;</li>
        </ul>

        <h2 class="subtitle">Informazioni inportanti</h2>
        <p>Si fa presente che alcune disposizioni di questi Termini potrebbero essere
          applicabili solo
          ad alcune categorie di Utenti. In particolare, alcune disposizioni potrebbero applicarsi solo a Consumatori o
          solo a
          Utenti che non agiscono come Consumatori. Tali limitazioni sono sempre esplicitamente menzionate in ciascuna
          clausola
          interessata. In caso di mancata menzione, le clausole si applicano a tutti gli Utenti.</p>
        <p>Il diritto di recesso si applica solo nei confronti di Consumatori Europei.</p>

        <h2 class="subtitle">Condizioni d'uso</h2>
        <p>Salvo dove diversamente specificato, le condizioni d'uso di questa Applicazione
          descritte in
          questa sezione sono valide in generale. Ulteriori condizioni d'uso o d'accesso applicabili in situazioni
          particolari
          sono esplicitamente indicate in questo documento. Utilizzando questa Applicazione, l'Utente dichiara di
          soddisfare i
          seguenti requisiti:</p>
        <ul>
          <li>Non ci sono restrizioni per gli Utenti in termini di essere Consumatori o Utenti Professionisti;</li>
          <li>L'Utente non si trova in un paese soggetto a embargo governativo da parte degli Stati Uniti d'America o in
            un
            paese incluso nell'elenco degli stati che sponsorizzano il terrorismo dal Governo degli Stati Uniti
            d'America;</li>
          <li>L'Utente non è incluso in alcuna lista di contraenti non ammessi dal Governo degli Stati Uniti d'America;
          </li>
        </ul>
        <h2 class="subtitle">Creazione di un Account</h2>
        <p>Per poter usufruire del Servizio, l'Utente deve creare un account fornendo tutti i dati e le informazioni
          richieste in modo completo e veritiero. Non è possibile accedere al Servizio senza un account Utente. Gli
          Utenti sono responsabili della sicurezza e della riservatezza delle proprie credenziali di accesso. Devono
          quindi selezionare una password che rispetti il massimo livello di sicurezza offerto da questa Applicazione.
          Creando un account, l'Utente si assume la piena responsabilità di tutte le attività svolte con le sue
          credenziali di accesso. Gli Utenti devono notificare immediatamente il Titolare, attraverso i contatti
          indicati in questo documento, se sospettano che le loro informazioni personali, come l'account Utente, le
          credenziali di accesso o i dati personali, siano state compromesse, divulgate illegalmente o rubate.</p>

        <h2 class="subtitle">Condizioni per la Registrazione</h2>
        <p>La creazione di un account Utente su questa Applicazione è soggetta alle seguenti condizioni. Creando un
          account, l'Utente conferma di rispettare queste condizioni:</p>
        <ul>
          <li>Non è permesso creare account tramite bot o altri strumenti automatizzati.</li>
          <li>Salvo diversa indicazione, ogni Utente può creare solo un account.</li>
        </ul>

        <h2 class="subtitle">Chiusura dell'Account</h2>
        <p>L'Utente ha la libertà di chiudere il proprio account e interrompere l'utilizzo del Servizio in qualsiasi
          momento, seguendo questa procedura:</p>
        <ul>
          <li>Contattando il Titolare ai recapiti indicati in questo documento.</li>
        </ul>
        <p>La chiusura dell'account Utente sarà tuttavia sospesa fino alla scadenza di eventuali servizi a pagamento in
          abbonamento acquistati dall’Utente.</p>

        <h2 class="subtitle">Sospensione e Cancellazione dell'Account</h2>
        <p>Il Titolare si riserva il diritto di sospendere o cancellare l'account di un Utente in qualsiasi momento a
          sua discrezione e senza preavviso, se lo ritiene inappropriato, offensivo o in violazione di questi Termini.
          La sospensione o cancellazione dell'account non dà all'Utente alcun diritto a risarcimenti, rimborsi o
          indennizzi. La sospensione o cancellazione di un account per motivi imputabili all'Utente non esonera l'Utente
          dal pagamento di eventuali costi o tariffe applicabili.</p>

        <h2 class="subtitle">Contenuti su questa Applicazione</h2>
        <p>Salvo diversa indicazione o chiara riconoscibilità, tutti i contenuti disponibili su questa Applicazione sono
          di proprietà del Titolare o dei suoi licenzianti. Il Titolare si impegna a fare in modo che il contenuto
          disponibile su questa Applicazione non violi la legge applicabile o i diritti di terzi. Tuttavia, non è sempre
          possibile garantire questo. In tali casi, senza pregiudizio per i diritti e le pretese legalmente
          esercitabili, gli Utenti sono invitati a inviare i relativi reclami ai contatti indicati in questo documento.
        </p>

        <h2 class="subtitle">Diritti sui Contenuti di questa Applicazione</h2>
        <p>Il Titolare detiene e si riserva tutti i diritti di proprietà intellettuale sui contenuti sopra menzionati.
          Gli Utenti non sono autorizzati a utilizzare i contenuti in alcun modo che non sia necessario o implicito
          nell'uso corretto del Servizio. In particolare, ma senza limitazioni, è vietato agli Utenti copiare,
          scaricare, condividere oltre i limiti specificati, modificare, tradurre, elaborare, pubblicare, trasmettere,
          vendere, concedere in sublicenza, trasformare, trasferire o cedere a terzi o creare opere derivate dai
          contenuti disponibili su questa Applicazione, o permettere a terzi di fare tali attività tramite il proprio
          account Utente o dispositivo, anche senza la loro conoscenza. Laddove espressamente indicato su questa
          Applicazione, l'Utente è autorizzato a scaricare, copiare e/o condividere determinati contenuti disponibili su
          questa Applicazione esclusivamente per uso personale e non commerciale, a condizione che sia rispettata
          l'attribuzione dell'autore dell'opera e ogni altra indicazione rilevante richiesta dal Titolare. Restano
          valide le limitazioni ed esclusioni previste dalla legge sul diritto d'autore.</p>

        <h2 class="subtitle">Materiali condivisi dagli Utenti</h2>
        <p>L'Amministratore permette agli Utenti di caricare, distribuire o proporre i propri materiali su questa
          Piattaforma. Quando l'Utente fornisce materiali a questa Piattaforma, afferma di avere l'autorizzazione legale
          per farlo e assicura che tali materiali non infrangono leggi o diritti di terzi. I criteri aggiuntivi che i
          materiali devono rispettare per essere considerati "accettabili" sono indicati nella sezione sull'uso
          consentito.</p>

        <h2 class="subtitle">Diritti sui materiali condivisi dagli Utenti</h2>
        <p>L'Utente comprende e accetta che, fornendo i propri materiali a questa Piattaforma, concede
          all'Amministratore il diritto non esclusivo di utilizzare i materiali per il funzionamento e la manutenzione
          di questa Piattaforma, come previsto dal contratto. Entro i limiti della legge, l'Utente rinuncia
          all'esercizio di diritti morali in relazione ai materiali forniti a questa Piattaforma. Gli Utenti comprendono
          e accettano che i materiali da loro proposti tramite questa Piattaforma saranno resi disponibili alle stesse
          condizioni applicabili ai materiali di questa Piattaforma.</p>

        <h2 class="subtitle">Responsabilità per i materiali condivisi</h2>
        <p>L'Utente è l'unico responsabile dei materiali caricati, pubblicati, distribuiti o comunque forniti a questa
          Piattaforma. L'Utente comprende e accetta che l'Amministratore non filtra né modera tali materiali. Tuttavia,
          l'Amministratore si riserva il diritto di rimuovere, eliminare o bloccare tali materiali a sua discrezione e
          di negare senza preavviso all'Utente che li ha caricati l'accesso a questa Piattaforma:</p>
        <ul>
          <li>quando viene a conoscenza di una (presunta) violazione di questi Termini, dei diritti di terzi o della
            legge applicabile, in relazione a tali materiali;</li>
          <li>se riceve una notifica di violazione dei diritti di proprietà intellettuale;</li>
          <li>se riceve una notifica di violazione della privacy di terzi, compresa la loro sfera privata;</li>
          <li>per ordine dell'Autorità; o</li>
          <li>se viene portato alla sua attenzione che tali materiali, se accessibili tramite questa Piattaforma,
            possono rappresentare un rischio per gli Utenti, per terzi o per la disponibilità del Servizio.</li>
        </ul>
        <p>La rimozione, l'eliminazione o il blocco dei materiali non danno diritto a nessuna richiesta di risarcimento,
          rimborso o indennizzo da parte degli Utenti che hanno fornito tali materiali. Gli Utenti accettano di tenere
          indenne l'Amministratore da e contro ogni richiesta avanzata e/o danno subito a causa di materiali da essi
          forniti a o proposti tramite questa Piattaforma.</p>

        <h2 class="subtitle">Accesso a risorse di terze parti</h2>
        <p>Attraverso questa Applicazione, gli Utenti possono accedere a risorse fornite da entità terze. Gli Utenti
          comprendono e accettano che il Titolare non ha alcuna autorità su tali risorse e, di conseguenza, non è
          responsabile per il loro contenuto e la loro disponibilità. Le condizioni relative alle risorse fornite da
          terzi, comprese quelle relative a eventuali concessioni di diritti sui contenuti, sono stabilite dai terzi
          stessi e regolate dai rispettivi termini e condizioni o, in mancanza di questi, dalla legge.</p>

        <h2 class="subtitle">Utilizzo consentito</h2>
        <p>Questa Applicazione e il Servizio possono essere utilizzati esclusivamente per gli scopi per i quali sono
          proposti, in conformità con questi Termini e secondo la legge vigente. Spetta unicamente all'Utente
          assicurarsi che l'uso di questa Applicazione e/o del Servizio non violi la legge, i regolamenti o i diritti di
          terzi. Di conseguenza, il Titolare si riserva il diritto di adottare tutte le misure appropriate per tutelare
          i propri interessi legittimi, e in particolare di negare all'Utente l'accesso a questa Applicazione o al
          Servizio, risolvere contratti, segnalare qualsiasi attività censurabile svolta tramite questa Applicazione o
          il Servizio alle autorità competenti - ad es. l'autorità giudiziaria o amministrativa - ogniqualvolta l'Utente
          commetta o vi sia il sospetto che commetta:</p>
        <ul>
          <li>violazioni di legge, regolamenti e/o dei Termini;</li>
          <li>lesioni di diritti di terzi;</li>
          <li>atti che possono danneggiare significativamente i legittimi interessi del Titolare;</li>
          <li>offese al Titolare o a un terzo.</li>
        </ul>

        <h2 class="subtitle">TERMINI E CONDIZIONI DI VENDITA</h2>

        <h2 class="subtitle">Fornitura di dati personali</h2>
        <p>Per accedere o ricevere alcuni dei Prodotti forniti tramite questa Applicazione come parte del Servizio, agli
          Utenti potrebbe essere richiesto di fornire i propri dati personali come indicato su questa Applicazione.</p>

        <h2 class="subtitle">Prodotti a pagamento</h2>
        <p>Alcuni dei Prodotti offerti su questa Applicazione come parte del servizio sono a pagamento. Le tariffe, la
          durata e le condizioni applicabili alla vendita di tali Prodotti sono descritte di seguito e nelle rispettive
          sezioni di questa Applicazione. Per acquistare i Prodotti, l’Utente è tenuto a registrarsi o ad effettuare
          l’accesso a questa Applicazione.</p>

        <h2 class="subtitle">Descrizione del prodotto</h2>
        <p>Prezzi, descrizioni e disponibilità dei Prodotti sono specificati nelle rispettive sezioni di questa
          Applicazione e sono soggetti a modifica senza preavviso. Sebbene i Prodotti su questa Applicazione siano
          presentati con la massima accuratezza tecnicamente possibile, la rappresentazione su questa Applicazione
          tramite qualsiasi mezzo (inclusi, a seconda dei casi, materiali grafici, immagini, colori, suoni) è da
          intendersi come mero riferimento e non implica alcuna garanzia in merito alle caratteristiche del Prodotto
          acquistato. Le caratteristiche del Prodotto selezionato saranno specificate durante la procedura d’acquisto.
        </p>

        <h2 class="subtitle">Procedura d’acquisto</h2>
        <p>Ogni fase, dalla scelta del prodotto fino all’inoltro dell’ordine, fa parte della procedura d’acquisto. La
          procedura d’acquisto comprende i seguenti passaggi:</p>
        <ul>
          <li>Gli Utenti sono pregati di scegliere il Prodotto desiderato e di verificare la loro scelta d’acquisto.
          </li>
          <li>Dopo aver controllato le informazioni visibili nella scelta d’acquisto, gli Utenti possono effettuare
            l’ordine inoltrandolo.</li>
        </ul>

        <h2 class="subtitle">Conferma dell'Ordine</h2>
        <p class="subtitle">Conseguenze della Conferma dell'Ordine</p>
        <ul>
          <li>La conferma dell'ordine da parte dell'utente implica la finalizzazione del contratto e l'assunzione da
            parte dell'utente dell'obbligo di pagare il prezzo, le tasse e qualsiasi altro costo e spesa, come indicato
            nella pagina dell'ordine.</li>
          <li>Se il Prodotto acquistato necessita di un'azione da parte dell'utente, come la fornitura di informazioni o
            dati personali, specifiche o richieste speciali, la conferma dell'ordine implica anche l'obbligo dell'utente
            di collaborare di conseguenza.</li>
          <li>Dopo aver confermato l'ordine, gli utenti riceveranno una conferma di ricezione dell'ordine. Tutte le
            notifiche relative al processo di acquisto descritto sopra saranno inviate all'indirizzo email fornito
            dall'utente per tale scopo.</li>
        </ul>

        <h2 class="subtitle">Costi</h2>
        <p class="subtitle">Informazioni sui Prezzi</p>
        <p>Durante il processo di acquisto e prima della conferma dell'ordine, gli utenti sono adeguatamente informati
          su tutte le commissioni, tasse e costi (inclusi eventuali costi di spedizione) che saranno addebitati loro. I
          prezzi su questa Applicazione:</p>
        <ul>
          <li>in base alla sezione che l'utente sta consultando, includono tutte le commissioni, tasse e costi
            applicabili o sono indicati al netto di commissioni, tasse e costi applicabili.</li>
        </ul>

        <h2 class="subtitle">Promozioni e Sconti</h2>
        <p class="subtitle">Offerte Speciali</p>
        <p>Il Titolare può offrire sconti o promozioni speciali per l'acquisto dei Prodotti. Queste promozioni o sconti
          sono sempre soggetti ai requisiti e ai termini e condizioni previsti nella relativa sezione di questa
          Applicazione. Le promozioni e le offerte sono sempre concesse a sola discrezione del Titolare. Le promozioni o
          gli sconti ripetuti o periodici non costituiscono alcun diritto o pretesa esigibile dagli utenti in futuro. A
          seconda dei casi, gli sconti e le promozioni sono validi per un determinato periodo di tempo o fino ad
          esaurimento delle scorte. Salvo diversamente specificato, i limiti di tempo delle promozioni e degli sconti si
          riferiscono al fuso orario della sede del Titolare, come indicato nei contatti in questo documento.</p>

        <h2 class="subtitle">Metodi di Pagamento</h2>
        <p class="subtitle">Informazioni sui Metodi di Pagamento</p>
        <p>I dettagli relativi ai metodi di pagamento accettati sono evidenziati durante il processo di acquisto. Alcuni
          metodi di pagamento possono essere soggetti a condizioni aggiuntive o comportare costi aggiuntivi. Le
          informazioni dettagliate sono riportate nella relativa sezione di questa Applicazione. Tutti i pagamenti sono
          gestiti autonomamente da servizi terzi. Pertanto, questa Applicazione non raccoglie dati relativi al pagamento
          - come numeri di carta di credito - ma riceve una notifica una volta che il pagamento è stato effettuato con
          successo. Per ulteriori informazioni sul trattamento dei dati personali e sui relativi diritti, l'utente può
          fare riferimento alla privacy policy di questa Applicazione. Se il pagamento effettuato con uno dei metodi
          disponibili non riesce o viene rifiutato dal fornitore del servizio di pagamento, il Titolare non è obbligato
          a eseguire l'ordine. Nel caso in cui il pagamento non sia andato a buon fine, il Titolare si riserva il
          diritto di richiedere all'utente il rimborso di qualsiasi spesa o danno correlato.</p>

        <h2 class="subtitle">Diritti d'uso riservati</h2>
        Fino a quando il Titolare non riceve il pagamento completo del prezzo di acquisto, l'Utente non ottiene i
        diritti d'uso dei Prodotti ordinati.

        <h2 class="subtitle">Consegna e Esecuzione dei servizi</h2>
        <p>Il servizio acquistato sarà fornito o reso disponibile nei tempi indicati su questa Applicazione o come
          comunicato prima dell'invio dell'ordine.</p>

        <h2 class="subtitle">Durata del contratto e Abbonamenti</h2>
        <p>Con l'abbonamento, l'Utente riceve un Prodotto in modo continuo o periodico. I dettagli relativi al tipo di
          abbonamento e alla risoluzione sono descritti di seguito.</p>

        <h2 class="subtitle">Abbonamenti a tempo indeterminato</h2>
        <p>Gli abbonamenti a pagamento iniziano il giorno in cui il Titolare riceve il pagamento. Per mantenere attivo
          l'abbonamento, l'Utente deve pagare tempestivamente il corrispettivo periodico richiesto. In caso contrario,
          il servizio potrebbe essere interrotto.</p>

        <h2 class="subtitle">Risoluzione di abbonamenti a tempo indeterminato</h2>
        <p>Gli abbonamenti a tempo indeterminato possono essere terminati in qualsiasi momento inviando una
          comunicazione di disdetta chiara e inequivocabile al Titolare, utilizzando i contatti riportati in questo
          documento o - se applicabile – seguendo le istruzioni su questa Applicazione. La risoluzione avrà effetto 30
          giorni dopo la ricezione della disdetta da parte del Titolare.</p>

        <h2 class="subtitle">Diritti dell’Utente</h2>
        <p>A meno che non sussista un'eccezione, l’Utente potrebbe avere il diritto di recedere dal contratto entro il
          termine specificato (generalmente 14 giorni) per qualsiasi motivo e senza giustificazione. L’Utente può
          trovare ulteriori informazioni sul diritto di recesso in questa sezione.</p>

        <h2 class="subtitle">Chi gode del diritto di recesso</h2>
        <p>Se non si verifica una delle eccezioni elencate di seguito, gli Utenti che agiscono come Consumatori Europei
          hanno per legge il diritto di recedere dai contratti conclusi online (contratti a distanza) entro il periodo
          di tempo specificato per qualsiasi motivo e senza bisogno di giustificazione. Gli Utenti che non soddisfano
          tali requisiti non godono dei diritti descritti in questa sezione. Il Consumatore sarà responsabile nei
          confronti del Venditore solo per la diminuzione del valore dei beni risultante da una manipolazione dei beni
          diversa da quella necessaria per stabilire la natura, le caratteristiche e il funzionamento dei beni.</p>

        <h2 class="subtitle">Esercizio del diritto di recesso</h2>
        <p>Per esercitare il diritto di recesso l’Utente deve inviare al Titolare una comunicazione inequivocabile della
          propria intenzione di recedere dal contratto. A tal fine, l’Utente può utilizzare il modulo di recesso tipo
          reperibile nella sezione delle definizioni di questo documento. L’Utente è tuttavia libero di esprimere la
          propria intenzione di recedere dal contratto in ogni altra forma idonea. Al fine di rispettare il periodo
          entro il quale dev’essere esercitato il diritto, l’Utente deve inviare la dichiarazione di recesso prima che
          scada il termine di recesso.</p>

        <h2 class="subtitle">Quando scade il termine di recesso?</h2>
        <p>In caso di contratti relativi alla prestazione di un servizio il termine di recesso scade dopo 14 giorni dal
          giorno di conclusione del contratto, a meno che l’Utente abbia rinunciato al diritto di recesso.</p>

        <h2 class="subtitle">Effetti del recesso</h2>
        <p>Il Titolare rimborsa tutti i pagamenti ricevuti compresi, se effettuati, quelli relativi alle spese di
          consegna agli Utenti che abbiano correttamente esercitato il diritto di recesso. Tuttavia, il maggior costo
          derivante dalla scelta di un particolare metodo di consegna diverso dalla consegna standard più economica
          offerta dal Titolare, resterà a carico dell’Utente. Il rimborso avviene senza indebito ritardo ed in ogni caso
          entro 14 giorni dal giorno in cui il Titolare è stato informato della decisione dell’Utente di recedere dal
          contratto. Salvo ove diversamente pattuito con l’Utente, il rimborso è effettuato utilizzando lo stesso mezzo
          di pagamento utilizzato per la transazione iniziale. L’Utente non deve sostenere alcun costo quale conseguenza
          del recesso.</p>

        <h2 class="subtitle">Contratti relativi a un servizio</h2>
        <p>Se l’Utente esercita il diritto di recesso dopo aver richiesto che l’erogazione del servizio inizi prima
          dello scadere del periodo di recesso, l’Utente sarà tenuto a pagare al Titolare un compenso proporzionato alla
          parte di servizio erogata sino al momento in cui l’Utente ha informato il Titolare della propria intenzione di
          recedere rispetto all’intero servizio previsto dal contratto. Il compenso sarà calcolato sulla base del prezzo
          contrattualmente pattuito e sarà proporzionale alla parte di servizio erogata fino al momento in cui l’Utente
          esercita il recesso rispetto all’intero servizio previsto dal contratto.</p>

        <h1>UK Consumer Rights</h1>
        <h2 class="subtitle">Cancellation Right</h2>
        <p>Unless there are exceptions, UK Consumers have a statutory cancellation right under UK law. They may be
          eligible to withdraw from online contracts (distance contracts) within the specified period (usually 14 days),
          for any reason and without any justification. This right does not apply to Users who are not Consumers. More
          information about the cancellation conditions can be found in this section.</p>

        <h2 class="subtitle">Exercising Cancellation Right</h2>
        <p>To exercise their cancellation right, Users must send the Owner a clear statement of their intention to
          withdraw from the contract. Users can use the model withdrawal form found in the \"definitions\" section of
          this document. However, Users are free to express their intention to withdraw from the contract in any other
          appropriate manner. To meet the deadline for exercising this right, Users must send the withdrawal notice
          before the cancellation period ends.</p>

        <h2 class="subtitle">Cancellation Period Expiry</h2>
        <ul>
          <li>For service purchases, the cancellation period ends 14 days after the contract is entered into, unless the
            User has waived the cancellation right.</li>
        </ul>

        <h2 class="subtitle">Cancellation Effects</h2>
        <p>Users who properly withdraw from a contract will be refunded by the Owner for all payments made to the Owner,
          including delivery costs, if any. However, any additional costs resulting from the choice of a specific
          delivery method other than the least expensive standard delivery offered by the Owner, will not be refunded.
          This refund will be made without undue delay and, in any case, no later than 14 days from the day the Owner is
          informed of the User's decision to withdraw from the contract. Unless otherwise agreed with the User, refunds
          will be made using the same payment method as used for the initial transaction. In any case, the User will not
          incur any costs or fees as a result of this refund.</p>

        <h2 class="subtitle">ON SERVICE PURCHASES</h2>
        <p>If a User exercises the cancellation right after requesting that the service be performed before the
          cancellation period ends, the User must pay the Owner an amount proportional to the part of the service
          provided. This payment will be calculated based on the contractually agreed fee and will be proportional to
          the part of the service provided until the User withdraws, compared to the full coverage of the contract.</p>

        <h2 class="subtitle">Garanzia di conformità dei prodotti digitali secondo la legge dell'Unione Europea</h2>
        <p>Secondo la legge europea, il venditore garantisce la conformità dei prodotti digitali forniti ai consumatori
          per un periodo minimo di 2 anni dalla consegna. Nel caso di prodotti digitali forniti continuamente per più di
          2 anni, la garanzia si estende per l'intero periodo di fornitura. Se gli utenti agiscono come consumatori
          europei, la garanzia legale di conformità si applica ai prodotti digitali disponibili su questa applicazione
          in conformità alle leggi del paese in cui risiedono abitualmente. Le leggi nazionali di tale paese possono
          concedere agli utenti diritti più ampi.</p>

        <h2 class="subtitle">Limitazione di responsabilità e manleva</h2>
        <p>Salvo diversamente specificato o concordato con gli utenti, la responsabilità del titolare per danni connessi
          all'esecuzione dell'accordo sarà esclusa, limitata e/o ridotta nei limiti massimi consentiti dalla legge
          applicabile.</p>

        <h2 class="subtitle">Manleva</h2>
        <p>L’utente si impegna a manlevare e tenere indenne il titolare e i suoi sottoposti, affiliati, funzionari,
          agenti, contitolari del marchio, partner e dipendenti nella misura di legge da qualsivoglia rivendicazione o
          pretesa – compresi, senza alcuna limitazione, oneri e spese legali – avanzata da terzi a causa di o in
          collegamento con comportamenti in violazione dei presenti termini, di diritti di terzi o di legge, posti in
          essere in collegamento con l'utilizzo del servizio e addebitabili all’utente, ai suoi affiliati, funzionari,
          agenti, contitolari del marchio, partner e dipendenti, a titolo di colpa. Questo si applica anche a qualsiasi
          rivendicazione esercitata da terzi (inclusi, a titolo meramente esemplificativo, i clienti del titolare) nei
          confronti del titolare in relazione ai prodotti digitali forniti dall'utente, quali, ad esempio,
          rivendicazioni sulla conformità.</p>

        <h2 class="subtitle">Limitazione della responsabilità per le attività dell’utente su questa applicazione</h2>
        <p>Salvo ove diversamente specificato e fatte salve le disposizioni di legge applicabili, è esclusa ogni pretesa
          risarcitoria nei confronti del titolare (o di qualsiasi persona fisica o giuridica che agisca per suo conto).
          Questo non limita la responsabilità del titolare per morte, danno alla persona o all’integrità fisica o
          mentale, danni derivanti dalla violazione di obblighi contrattuali essenziali, quali gli obblighi strettamente
          necessari al raggiungimento della causa del contratto, e/o ai danni provocati con dolo o colpa grave, a
          condizione che l’utilizzo di questa applicazione da parte dell’utente sia stato idoneo e corretto. Salvo che i
          danni siano stati causati con dolo o colpa grave o incidano sulla vita e/o l’integrità personale, fisica o
          mentale, il titolare risponde solo nella misura del danno tipico per il tipo di contratto e prevedibile al
          momento della conclusione.</p>

        <h2 class="subtitle">Utenti australiani</h2>
        <h3>Limitazione della responsabilità</h3>
        <p>Nessuna disposizione di questi termini esclude, limita o modifica alcuna garanzia, condizione, manleva,
          diritto o tutela che l’utente possa avere ai sensi del Competition and Consumer Act 2010 (Cth) o di altre
          simili legislazioni statali e territoriali e che costituisca un diritto che non può in alcun modo essere
          escluso, limitato o modificato (diritto non escludibile). Nei limiti massimi consentiti dalla legge, la nostra
          responsabilità nei confronti dell’utente, inclusa la responsabilità per la violazione di un diritto non
          escludibile e ogni altra responsabilità non altrimenti esclusa ai sensi di questi termini e condizioni, è
          limitata, a discrezione del titolare, ad una nuova fornitura dei servizi o al pagamento del costo per la
          ripetizione della loro fornitura.</p>

        <h2 class="subtitle">Utenti USA</h2>
        <h3>Esclusione di garanzia</h3>
        <p>Il titolare fornisce questa applicazione “così com’è” e secondo disponibilità. L’utilizzo del servizio è a
          rischio e pericolo dell’utente.
        </p>
        <p>
          Nei limiti massimi consentiti dalla legge, il titolare esclude espressamente
          le condizioni, pattuizioni e garanzie di qualsiasi tipo – siano esse espresse, implicite, statuarie o di altro
          tipo, comprese, a titolo esemplificativo ma non esaustivo, eventuali garanzie implicite di commerciabilità, di
          idoneità per un fine particolare o di non-violazione dei diritti di terzi. Nessuna consulenza o informazione,
          sia essa orale o scritta, che l’utente abbia ottenuto dal titolare o tramite il servizio creerà garanzie non
          espressamente previste nel presente documento.
        </p>
        <p>Fermo restando quanto sopra, il titolare e i suoi sottoposti,
          affiliati, funzionari, agenti, contitolari del marchio, partner, fornitori e dipendenti non garantiscono che
          il contenuto sia accurato, affidabile o corretto; che il servizio sarà disponibile, in maniera ininterrotta e
          sicura, in qualsiasi momento o luogo particolare; che eventuali difetti o errori saranno corretti; o che il
          servizio sia privo di virus o altri componenti dannosi.
        </p>
        <p>
          Qualsiasi contenuto scaricato o altrimenti ottenuto
          tramite l’utilizzo del servizio è scaricato a rischio e pericolo dell’utente e l’utente è l’unico responsabile
          per qualsiasi danno al sistema informatico o al dispositivo mobile o per la perdita di dati risultante da tale
          operazione o dall’uso del servizio da parte dell’utente. Il titolare non garantisce, approva, assicura o si
          assume la responsabilità per nessun prodotto o servizio pubblicizzato o offerto da parti terze attraverso il
          servizio né nessun sito web o servizio collegato tramite hyperlink. Inoltre il titolare non prende parte né in
          alcun modo monitora nessuna transazione tra utenti e fornitori terzi di prodotti o servizi. Il servizio
          potrebbe diventare inaccessibile o non funzionare correttamente con il browser, dispositivo e/o sistema
          operativo dell’utente.
        </p>
        <p>Il titolare non può essere ritenuto responsabile per nessun danno, sia esso percepito o
          effettivo, derivante dal contenuto, funzionamento o utilizzo del servizio. Leggi federali, alcuni stati e
          altre giurisdizioni non consentono l’esclusione e la limitazione di determinate garanzie implicite. Le
          esclusioni di cui sopra potrebbero non applicarsi agli utenti. Questo accordo conferisce agli utenti diritti
          legali particolari.
        </p>
        <p>
          Gli utenti potrebbero godere di diritti ulteriori che variano da stato a stato. Le
          limitazioni ed esclusioni previste da questo accordo si applicano nei limiti previsti dalla legge.</p>

        <h3>Limitazione della responsabilità</h3>
        <p>Nei limiti massimi consentiti dalla legge applicabile, in nessun caso il titolare e i suoi sottoposti,
          affiliati, funzionari, agenti, contitolari del marchio, partner, fornitori e dipendenti potranno essere
          ritenuti responsabili per :
        </p>
        <div>
          <ul>
            <li>
              qualsiasi danno indiretto, intenzionale, collaterale, particolare, consequenziale o
              esemplare, inclusi, a titolo esemplificativo ma non esaustivo, danni derivanti dalla perdita di profitti,
              di
              avviamento, d’uso, di dati o altre perdite immateriali, derivanti da o relativi all’uso, o
              all’impossibilità
              di usare il servizio
            </li>
            <li>
              qualsiasi danno, perdita o lesione risultante da hackeraggio, manomissione o da altro
              accesso o uso non autorizzato del servizio o dell’account utente o delle informazioni in esso contenute
            </li>
            <li>
              qualsiasi errore, mancanza o imprecisione nei contenuti
            </li>
            <li>
              lesioni personali o danni materiali, di qualsiasi
              natura, derivanti dall’accesso o dall’utilizzo del servizio da parte dell’utente
            </li>
            <li>
              qualsiasi accesso non
              autorizzato ai server di sicurezza del titolare e/o a qualsivoglia informazione personale ivi memorizzata
            </li>
            <li>
              qualsiasi interruzione o cessazione delle trasmissioni da o verso il servizio
            </li>
            <li>
              qualsiasi bug, virus, trojan o
              simili che possono essere trasmessi al o tramite il servizio
            </li>
            <li>
              qualsiasi errore o omissione in qualsiasi
              contenuto o per qualsiasi perdita o danno subiti a seguito dell’uso di qualsiasi contenuto pubblicato,
              inviato
              via email, trasmesso o altrimenti reso disponibile attraverso il servizio;
            </li>
            <li>
              Condotta diffamatoria,
              offensiva o illegale di qualsiasi utente o di terzi
            </li>
          </ul>
        </div>
        <p>
          In nessun caso il titolare e i suoi sottoposti,
          affiliati, funzionari, agenti, contitolari del marchio, partner, fornitori e dipendenti potranno essere
          ritenuti responsabili per qualsiasi richiesta di risarcimento, procedimento, responsabilità, obbligo, danno,
          perdita o costo per un importo superiore a quello pagato dall’utente al titolare nel corso dei 12 mesi
          precedenti, o per il periodo di durata di questo accordo tra il titolare e l’utente, a seconda di quale dei
          due è più breve.
        </p>
        <p>
          Questa sezione sulla limitazione di responsabilità si applica nei limiti massimi consentiti
          dalla legge nella giurisdizione applicabile, indipendentemente dal fatto che la presunta responsabilità derivi
          da contratto, atto illecito, negligenza, responsabilità oggettiva o da qualsiasi altro fondamento, anche se
          l'utente era stato avvisato della possibilità del verificarsi di tale danno. Alcune giurisdizioni non
          consentono l’esclusione o la limitazione dei danni collaterali e consequenziali, pertanto le limitazioni o
          esclusioni di cui sopra potrebbero non applicarsi all’utente. Questi termini conferiscono all’utente diritti
          legali specifici e l’utente potrebbe godere di altri diritti che variano da giurisdizione a giurisdizione. Le
          deroghe, esclusioni o limitazioni di responsabilità previste da questi termini non si applicano oltre i limiti
          previsti dalla legge applicabile.</p>

        <h2 class="subtitle">Manleva</h2>
        <p>L’utente si impegna a difendere, manlevare e tenere indenne il titolare e i suoi sottoposti, affiliati,
          funzionari, agenti, contitolari del marchio, partner, fornitori e dipendenti da e contro qualsivoglia
          rivendicazione o pretesa, danno, obbligazione, perdita, responsabilità, onere o debito e spesa, compresi,
          senza alcuna limitazione, oneri e spese legali derivanti da:
        </p>
        <div>
          <ul>
            <li>L’uso o l’accesso al servizio da parte dell’utente, compresi gli eventuali dati o contenuti trasmessi o
              ricevuti dall’utente</li>
            <li>La violazione di questi termini da parte dell’utente, comprese, a titolo esemplificativo ma non
              esaustivo, eventuali violazioni da parte dell’utente di qualsivoglia dichiarazione o garanzia prevista da
              questi termini</li>
            <li>La violazione da parte dell’utente di qualsiasi diritto di terzi, compreso, ma non limitato a, qualsiasi
              diritto relativo alla privacy o alla proprietà intellettuale</li>
            <li>La violazione da parte dell’utente di qualsiasi legge, norma o regolamento vigente</li>
            <li>Qualsiasi contenuto inviato dall’account dell’utente, incluse, a titolo esemplificativo ma non
              esaustivo, informazioni fuorvianti, false o imprecise e compreso anche il caso in cui l’accesso venga
              effettuato da terzi con username e password personali dell’utente o altre misure di sicurezza, se presenti
            </li>
            <li>La condotta dolosa dell’utente</li>
            <li>La violazione di qualsivoglia disposizione legale da parte dell’utente o dei suoi affiliati, funzionari,
              agenti, contitolari del marchio, partner, fornitori e dipendenti, nei limiti consentiti dalla legge
              applicabile</li>
          </ul>
        </div>

        <h2 class="subtitle">Regolamenti Generali</h2>

        <p>Non esiste una rinuncia tacita. L'omissione nell'esercitare i diritti legali o le rivendicazioni derivanti da
          questi Termini da parte del Proprietario non implica una rinuncia a tali diritti. Nessuna rinuncia può essere
          considerata definitiva in relazione a un diritto specifico o a qualsiasi altro diritto.</p>

        <h2 class="subtitle">Sospensione del Servizio</h2>

        <p>Per assicurare il massimo livello di servizio, il Proprietario si riserva il diritto di sospendere il
          Servizio per manutenzione, aggiornamenti di sistema o per qualsiasi altra modifica, informando adeguatamente
          gli Utenti. Entro i limiti della legge, il Proprietario può sospendere o interrompere completamente l'attività
          del Servizio. In caso di interruzione del Servizio, il Proprietario si impegnerà a consentire agli Utenti di
          recuperare i propri Dati Personali e le informazioni e rispetterà i diritti degli Utenti relativi all'uso
          continuato del prodotto e/o al risarcimento, secondo le disposizioni di legge. Inoltre, il Servizio potrebbe
          non essere disponibile per cause al di fuori del ragionevole controllo del Proprietario, come cause di forza
          maggiore (ad es. guasti infrastrutturali, blackout ecc.).</p>

        <h2 class="subtitle">Rivendita del Servizio</h2>

        <p>Gli Utenti non sono autorizzati a riprodurre, duplicare, copiare, vendere, rivendere o sfruttare questa
          Applicazione o il Servizio in tutto o in parte senza il previo consenso scritto del Proprietario, espresso
          direttamente o attraverso un legittimo programma di rivendita.</p>

        <h2 class="subtitle">Politica sulla Privacy</h2>

        <p>Le informazioni sul trattamento dei Dati Personali sono contenute nella politica sulla privacy di questa
          Applicazione.</p>

        <h2 class="subtitle">Proprietà Intellettuale</h2>

        <p>Senza pregiudizio per qualsiasi disposizione più specifica contenuta nei Termini, i diritti di proprietà
          intellettuale e industriale, come ad esempio diritti d'autore, marchi, brevetti e modelli relativi a questa
          Applicazione sono detenuti esclusivamente dal Proprietario o dai suoi licenziatari e sono protetti dalle leggi
          e dai trattati internazionali applicabili alla proprietà intellettuale. Tutti i marchi - nominativi o
          figurativi - e ogni altro segno distintivo, nome commerciale, marchio di servizio, illustrazione, immagine o
          logo che appaiono in collegamento con questa Applicazione sono e rimangono di esclusiva proprietà del
          Proprietario o dei suoi licenziatari e sono protetti dalle leggi e dai trattati internazionali applicabili
          alla proprietà intellettuale.</p>

        <h2 class="subtitle">Modifiche dei Termini</h2>

        <p>Il Proprietario si riserva il diritto di modificare i Termini in qualsiasi momento. In tal caso, il
          Proprietario darà adeguata notifica delle modifiche agli Utenti. Le modifiche avranno effetto nel rapporto con
          l'Utente solo a partire dal momento comunicato all'Utente. L'uso continuato del Servizio implica
          l'accettazione dell'Utente dei Termini aggiornati. Se l'Utente non desidera accettare le modifiche, deve
          cessare l'uso del Servizio e può recedere dall'Accordo. La versione precedente continua a regolare il rapporto
          fino all'accettazione delle modifiche da parte dell'Utente. Tale versione può essere richiesta al
          Proprietario. Se richiesto dalla legge, il Proprietario comunicherà in anticipo agli Utenti la data di entrata
          in vigore dei Termini modificati.</p>

        <h2 class="subtitle">Trasferimento del Contratto</h2>

        <p>Il Proprietario si riserva il diritto di trasferire, cedere, disporre di, rinnovare o subappaltare singoli o
          tutti i diritti e gli obblighi secondo questi Termini, tenendo conto degli interessi legittimi degli Utenti.
          Si applicano le disposizioni relative alla modifica di questi Termini. L'Utente non è autorizzato a cedere o
          trasferire i propri diritti e le proprie obbligazioni secondo i Termini senza il consenso scritto del
          Proprietario.</p>

        <h2 class="subtitle">Contatti</h2>

        <p>Tutte le comunicazioni relative all'uso di questa Applicazione devono essere inviate ai recapiti indicati in
          questo documento.</p>

        <h2 class="subtitle">Clausola di Salvaguardia</h2>

        <p>Se una qualsiasi delle disposizioni di questi Termini dovesse essere o diventare nulla o inefficace secondo
          la legge applicabile, la nullità o inefficacia di tale disposizione non causa l'inefficacia delle restanti
          disposizioni, che rimangono quindi valide ed efficaci.</p>

        <h2 class="subtitle">Utenti USA</h2>

        <p>Qualsiasi disposizione invalida o inefficace sarà interpretata ed adattata nei limiti necessari per renderla
          valida, efficace e conforme alla finalità originaria. Questi Termini costituiscono l'intero accordo tra Utente
          e Proprietario con riferimento all'oggetto regolato e prevalgono su ogni altra comunicazione, compresi
          eventuali accordi precedenti, tra le parti in merito all'oggetto regolato. Questi Termini saranno attuati
          nella più ampia misura consentita dalla legge.</p>

        <h2 class="subtitle">Utenti Europei</h2>

        <p>Se una disposizione di questi Termini dovesse essere o diventare nulla, invalida o inefficace, le parti si
          adopereranno per individuare in via amichevole una disposizione valida ed efficace sostitutiva di quella
          nulla, invalida o inefficace. In caso di mancato accordo nei termini predetti, se permesso o previsto dalla
          legge applicabile, la disposizione nulla, invalida o inefficace sarà sostituita dalla disciplina legale
          applicabile. Nonostante quanto sopra, la nullità, invalidità o inefficacia di una specifica disposizione di
          questi Termini non comporta la nullità dell'intero Accordo, a meno che le disposizioni nulle, invalidi o
          inefficaci nel quadro dell'Accordo siano essenziali o di tale importanza, che le parti non avrebbero concluso
          il contratto se avessero saputo che la disposizione sarebbe stata invalida, ovvero in casi in cui le
          disposizioni residue comporterebbero un onere eccessivo ed inaccettabile per una delle parti.</p>

        <h2 class="subtitle">Legge Applicabile</h2>

        <p>I Termini sono regolati dalla legge del luogo in cui è stabilito il Proprietario, come indicato nella
          relativa sezione di questo documento, indipendentemente dalle norme di conflitto.</p>

        <h2 class="subtitle">Prevalenza della Legge Nazionale</h2>

        <p>Tuttavia, nonostante quanto sopra, se la legge del paese in cui si trova l'Utente prevede un livello di
          protezione dei consumatori superiore, prevale tale superiore livello di protezione.</p>

        <h2 class="subtitle">Eccezione per Consumatori in Svizzera</h2>

        <p>Se l'Utente agisce come Consumatore in Svizzera, si applicherà la legge svizzera.</p>

        <h2 class="subtitle">Exceção para Consumidores no Brasil</h2>

        <p>Se o Usuário se qualificar como um Consumidor Brasileiro e o produto e/ou serviço for comercializado no
          Brasil, será aplicada a legislação brasileira.</p>

        <h2 class="subtitle">Foro Competente</h2>

        <p>La competenza esclusiva per conoscere qualsiasi controversia derivante da o in collegamento con i Termini
          spetta al giudice del luogo in cui il Proprietario è stabilito, come indicato nella relativa sezione di questo
          documento.</p>

        <h2 class="subtitle">Eccezione per Consumatori in Europa</h2>

        <p>Quanto precede non si applica a Utenti che agiscono come Consumatori Europei o Consumatori situati nel Regno
          Unito, Svizzera, Norvegia o Islanda.</p>

        <h2 class="subtitle">Exceção para Consumidores no Brasil</h2>

        <p>O acima não se aplica a Usuários no Brasil que se qualifiquem como Consumidores.</p>

        <h2 class="subtitle">Risoluzione delle Controversie</h2>

        <p>Composizione amichevole delle controversie Gli Utenti possono segnalare eventuali controversie al
          Proprietario, che cercherà di risolvere in via amichevole. Nonostante il diritto degli Utenti di promuovere
          un'azione in giudizio, in caso di controversie relative all'uso di questa Applicazione o al Servizio, gli
          Utenti sono pregati di contattare il Proprietario ai recapiti indicati in questo documento. L'Utente può
          indirizzare un reclamo all'indirizzo e-mail del Proprietario indicato in questo documento, includendo una
          breve descrizione e, se del caso, i dettagli dell'ordine, acquisto o account interessato. Il Proprietario
          provvederà a rispondere alla richiesta senza indebito ritardo e entro 5 giorni dalla sua ricezione.</p>
      </div>


      <h2 class="subtitle">Glossario e Riferimenti Legali</h2>

      <p><strong>Applicazione (o App)</strong>: Rappresenta la piattaforma che permette la erogazione del Servizio.</p>

      <p><strong>Contratto</strong>: Rappresenta qualsiasi legame giuridicamente vincolante o accordo contrattuale tra
        il Fornitore e l’Utente, regolato dai Termini.</p>

      <p><strong>Brasiliano (o Brasile)</strong>: Si riferisce a quando l'Utente, indipendentemente dalla sua
        nazionalità, si trova in Brasile.</p>

      <p><strong>Utente Commerciale</strong>: Rappresenta qualsiasi Utente che non rientra nella definizione di
        Consumatore.</p>

      <p><strong>Prodotto Digitale</strong>: Si tratta di un Prodotto che include:</p>
      <ul>
        <li>contenuti generati e forniti in formato digitale; e/o</li>
        <li>un servizio che permette la creazione, modifica, conservazione o accesso ai dati in formato digitale, o la
          condivisione o qualsiasi altra interazione con dati in formato digitale caricati o generati dall’Utente e da
          qualsiasi altro Utente di questa Applicazione.</li>
      </ul>

      <p><strong>Europeo (o Europa)</strong>: Si riferisce a quando l'Utente, indipendentemente dalla sua nazionalità,
        si trova nell’Unione Europea.</p>

      <p><strong>Fornitore (o Noi)</strong>: Rappresenta l'individuo o l'entità che mette a disposizione questa
        Applicazione e/o fornisce il Servizio agli Utenti.</p>

      <p><strong>Prodotto</strong>: Rappresenta un bene o servizio accessibile tramite questa Applicazione, come ad
        esempio un bene materiale, file digitali, software, servizi di prenotazione etc., e qualsiasi altro tipo di
        prodotto definito separatamente in questo documento, come i Prodotti Digitali.</p>

      <p><strong>Servizio</strong>: Rappresenta il servizio erogato tramite questa Applicazione come descritto nei
        Termini e su questa Applicazione.</p>

      <p><strong>Termini</strong>: Rappresentano tutte le condizioni applicabili all’uso di questa Applicazione e/o alla
        erogazione del Servizio come descritte in questo documento e in qualsiasi altro documento o accordo ad esso
        collegato, nella versione più recente.</p>

      <p><strong>Regno Unito (o UK)</strong>: Si riferisce a quando l'Utente, indipendentemente dalla sua nazionalità,
        si trova nel Regno Unito.</p>

      <p><strong>Utente</strong>: Rappresenta l'individuo o l'entità che fruisce del Servizio.</p>

      <p><strong>Utente (o Tu)</strong>: Rappresenta qualsiasi individuo che utilizza questa Applicazione.</p>

      <p><strong>Consumatore</strong>: Rappresenta qualsiasi Utente considerato tale secondo la legge applicabile.</p>

      <h2 class="subtitle">
        Modulo di recesso tipo
      </h2>
      <p>
        Indirizzato a:
      </p>
      <p>
        Dawit Abate Woldeamanuel, Via Attilio Magri 17, MN, 46100
      </p>
      <p>
        info@ristoclick.com
      </p>
      <p>
        Con la presente io/noi notifichiamo il recesso dal mio/nostro contratto di vendita dei seguenti beni/servizi:
      </p>
      <p>
        _____________________________________________ (inserire qui una descrizione dei beni/servizi dal cui acquisto si
        intende recedere)
      </p>
      <p>
        • Ordinato il: _____________________________________________ (inserire la data)
      </p>
      <p>
        • Ricevuto il: _____________________________________________ (inserire la data)
      </p>
      <p>
        • Nome del/dei consumatore/i:_____________________________________________
      </p>
      <p>
        • Indirizzo del/dei consumatore/i:_____________________________________________
      </p>
      <p>
        • Data: _____________________________________________
        (firmare solamente se il presente modulo è notificato in versione cartacea)
      </p>
    </div>
    <div>
      <p>
        Data ultima modifica: 01/02/2024
      </p>
    </div>
  </section>
</main>
