import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { CookieKey, CookieModel, CookieType } from 'src/app/model/cookie.model';
import { PlanSelectModel } from 'src/app/model/plan.model';
import { PlanEnum } from 'src/app/model/planEnum';
import { CookieService } from 'src/app/service/cookie/cookie.service';
import { PlanService } from 'src/app/service/plan-service/plan.service';
import { WaitingListService } from 'src/app/service/waiting-list/waiting-list.service';

@Component({
  selector: 'app-select-plan',
  templateUrl: './select-plan.component.html',
  styleUrls: ['./select-plan.component.scss']
})
export class SelectPlanComponent implements OnInit, OnChanges {
  @Input() showModal: boolean = true;
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
  cookieAndPrivacy: any;
  title: string = "Select a plan";
  isCookieDetails: boolean = false;
  formGroup!: FormGroup;
  cookieList: CookieModel[] = [];
  techCookieList: CookieModel[] = [];
  enableClose: boolean = true;
  constructor(
    private spinner: NgxSpinnerService,
    private cookieService: CookieService,
    private fb: FormBuilder,
    private waitingListService: WaitingListService,
    private planService: PlanService,
    private messageService: MessageService,
    private translate: TranslateService,
  ) {
      console.log(this.planService.plan);
     }


  ngOnChanges(changes: SimpleChanges): void {
    this.initForm();
  }

  ngOnInit() {
    this.initForm();
    this.translate.get('FORM.TITLE').subscribe((res: string) => {
      this.title = res;
    });
  }

  getPlan(plan: PlanEnum) {
    switch (plan) {
      case PlanEnum.Base:
        return 'Piano Base';
      case PlanEnum.Intermediate:
        return 'Piano Intermedio';
      case PlanEnum.Advanced:
        return 'Piano Avanzato';
      case PlanEnum.Enterprise:
        return 'Piano Enterprise';
      default:
        return '';
    }
  }

  submit() {
    //Validate form
    if (this.formGroup.valid) {
      this.spinner.show();
      let req = this.formToModel();
      this.waitingListService.submitWithPlan(req).subscribe(() => {
        this.spinner.hide();
        this.closeModal.emit();
        this.messageService.add({ severity: 'success', summary: 'Seleziona piano',
        life: 20000,
        detail: 'Grazie di esserti iscritto a RistoClick! Verrai contattato entro due giorni lavorativi dal team di RistoClick!' });
        this.formGroup.reset();
      },err=>{
        this.spinner.hide();
        this.messageService.add({ severity: 'error', summary: 'Seleziona piano', detail: 'Errore durante la selezione del piano. Si prega di riprovare. In caso di malfunzionamenti si prega di scriverci a info@ristoclick.com' });
      });
    } else {
      this.formGroup.markAllAsTouched();

    }

  }

  formToModel(): PlanSelectModel {
    return {
      name: this.formGroup.get('name')?.value,
      surname: this.formGroup.get('surname')?.value,
      email: this.formGroup.get('email')?.value,
      privacy: this.formGroup.get('privacy')?.value,
      plan: this.formGroup.get('plan')?.value,
      notes: this.formGroup.get('notes')?.value
    };
  }

  initForm() {
    this.formGroup = this.fb.group({
      privacy: [null, [Validators.required]],
      name: [null, [Validators.required]],
      surname: [null, [Validators.required]],
      email: [null, [Validators.required, Validators.email]],
      phone: [null, [Validators.required, Validators.minLength(10), Validators.pattern(/(^[0-9+]{1,}[0-9]{9,})|(^[0-9]{10,12})$/)]],
      plan: [this.getPlan(this.planService.plan

      ), [Validators.required]],
      notes: [null, []]
    });
    this.formGroup.get('plan')?.disable();
  }

  close() {
    this.closeModal.emit();
  }
}
