<p-dialog class="cookie-dialog" [ngClass]="enableClose ? '' : 'hide-cookie-dialog'" [header]="cookieTitle"
  [(visible)]="showCookieModal" styleClass="cookie-dialog-style">
  <div *ngIf="!isCookieDetails">
    <p>
      Utilizziamo i cookie per personalizzare contenuti ed annunci, per fornire funzionalità dei social media e per
      analizzare il nostro traffico.
      Acconsenti ai nostri cookie se continui ad utilizzare il nostro sito web.
    </p>
    <p>
      Continuando a navigare su questo sito, accetti l'utilizzo dei cookie definiti nella nostra
      <a (click)="cookieDetails(true)" class="cookie-link">Cookie Policy</a> e le policy sulla privacy che trovi a
      questi link:
      <a href="/privacy" target="_blank">Privacy</a>.
    </p>
    <form [formGroup]="cookieFormGroup">
      <div class="card flex justify-content-center">
        <div class="row cookie-row">
          <div class="col-8 col-sm-10">
            <p class="cookie-description">Cookie strettamente necessari</p>
          </div>
          <div class="col-4 col-sm-2">
            <p-inputSwitch formControlName="techCookie"></p-inputSwitch>
          </div>
        </div>
        <div class="row cookie-row">
          <div class="col-8 col-sm-10">
            <p class="cookie-description">Cookie per le statistiche</p>
          </div>
          <div class="col-4 col-sm-2">
            <p-inputSwitch formControlName="statCookie"></p-inputSwitch>
          </div>
        </div>
        <div class="row cookie-row">
          <div class="col-8 col-sm-10">
            <p class="cookie-description">Cookie per il marketing</p>
          </div>
          <div class="col-4 col-sm-2">
            <p-inputSwitch formControlName="marketingCookie"></p-inputSwitch>
          </div>
        </div>
      </div>
      <div class="row cookie-submit-container">
        <div class="col-md-6 col-12"><p-button class="cookie-details" label="Info" severity="info"
            (click)="cookieDetails(true)"></p-button></div>
        <div class="col-md-6 col-12"><p-button class="cookie-submit" label="Conferma scelta" (click)="submitCookie()"></p-button>
        </div>
      </div>
    </form>
  </div>
  <div *ngIf="isCookieDetails">
    <div>
      <p-button class="cookie-details cookie-details-back" label="Indietro" severity="info"
        (click)="cookieDetails(false)"></p-button>
    </div>
    <div class="cookie-details-content">
      <div class="card flex justify-content-center">
        <div class="card-contant">
          <b>Cookie strettamente necessari</b>
          <p>
            I cookie strettamente necessari devono essere abilitati in ogni momento in modo che possiamo salvare le tue
            preferenze per le impostazioni dei cookie nonchè per il corretto funzionamento del sito.
          </p>
          <p>
            Non è possibile disabilitare i cookie strettamente necessari.
          </p>
          <p-accordion [activeIndex]="0">
            <p-accordionTab header="Elenco cookie">
              <p-table [value]="this.techCookieList" [tableStyle]="{'min-width': '50rem'}">
                <ng-template pTemplate="header">
                  <tr>
                    <th>Nome</th>
                    <th>Durata</th>
                    <th>Descrizione</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-cookie>
                  <tr>
                    <td>{{ cookie.name }}</td>
                    <td>{{ cookie.duration >= 0 ? cookie.duration + ' '+cookie.durationType : cookie.durationType }}
                    </td>
                    <td>{{ cookie.description }}</td>
                  </tr>
                </ng-template>
              </p-table>
            </p-accordionTab>
          </p-accordion>

        </div>
      </div>

      <div class="card flex justify-content-center">
        <div class="card-contant">
          <b>Cookie per le statistiche</b>
          <p>
            I cookie per le statistiche ci aiutano a capire come i visitatori interagiscono con i siti web raccogliendo
            e segnalando informazioni.
            Questi coookie potrebero essere impostati da noi o da terze parti.
          </p>
          <p>
            Al momento il sito non utilizza cookie per le statistiche.
          </p>
        </div>
      </div>
      <div class="card flex justify-content-center">
        <div class="card-contant">
          <b>Cookie per il marketing</b>
          <p>
            I cookie per il marketing vengono utilizzati per tracciare i visitatori attraverso i siti web. L'intento è
            quello di visualizzare annunci che siano rilevanti e coinvolgenti per il singolo utente e quindi più
            preziosi per editori e inserzionisti di terze parti.
          </p>
          <p>
            Al momento il sito non utilizza cookie per il marketing.
          </p>
        </div>
      </div>
    </div>
    <div>
      <p-button class="cookie-details cookie-details-back" label="Indietro" severity="info"
        (click)="cookieDetails(false)"></p-button>
    </div>
  </div>
</p-dialog>
